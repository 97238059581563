import React, { useContext, useEffect, useState } from 'react';
import DefaultLayout from './DefaultLayout';
import Title from './styles/TitleStyle';
import { GeneralModalContext } from './GeneralModal';
import CampaignDetails from './Campaigns/CampaignDetails';
import ShareCampaign from './Campaigns/ShareCampaign';
import { ClientSearchBarStyle } from './styles/ClientSearchBarStyle';
import { MdClose, MdSearch } from 'react-icons/md';
import CampaignCard from '../ComponentsV2/Campaign/CampaignCard';
import DeleteModal from './Modals/DeleteModal';
import { Link } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import GeneralModalStyles from './styles/GeneralModalStyles';
import fetchInstance from '../utils/fetchInstance';
import FlexContainer from './styles/FlexContainer';
import { FolderPlus, FilePlus, Search } from 'react-bootstrap-icons';
import GroupCard from '../ComponentsV2/Group/GroupCard';
import Pagination from '../ComponentsV2/Pagination/Pagination';
import GroupModal from '../ComponentsV2/Group/GroupModal';
import GridContainer from './styles/GridContainer';
import { useDebounce } from '../hooks/useDebounce';
import config from '../config';

const style = {
  display: 'flex',
  flexWrap: 'wrap',
  overflowY: 'auto',
  margin: 0,
  padding: 0,
  marginRight: '-3rem',
};

export const GroupCampaignSearchBar = ({
  handleSearchCampaignInput,
  openCampaignModal,
  campaign = null,
  isCampaignGroup = false,
}) => {
  return (
    <ClientSearchBarStyle style={{ textAlign: 'right', gap: '10px' }}>
      <span className="search-client-wrapper">
        <Search />
        <input
          onChange={(e) => handleSearchCampaignInput(e)}
          id="search-client"
          type="text"
          placeholder="Search Group"
        />
      </span>
      <div className="filter-add-client">
        <button
          className="confirm-button"
          onClick={() => openCampaignModal(campaign, true)}
        >
          <FolderPlus />
          New group
        </button>
      </div>
    </ClientSearchBarStyle>
  );
};

export const CampaignSearchBar = ({
  handleSearchCampaignInput,
  openCampaignModal,
  campaign = null,
  isCampaignGroup = false,
}) => {
  return (
    <ClientSearchBarStyle style={{ textAlign: 'right', gap: '10px' }}>
      <span className="search-client-wrapper">
        <Search />
        <input
          onChange={(e) => handleSearchCampaignInput(e)}
          id="search-client"
          type="text"
          placeholder="Search Campaign"
        />
      </span>
      <div className="filter-add-client">
        <button
          className="confirm-button"
          onClick={() => openCampaignModal(campaign)}
        >
          <FilePlus />
          New campaign
        </button>
      </div>
    </ClientSearchBarStyle>
  );
};

const CampaignsMain = ({
  match,
  extractCSRF,
  setBanner,
  user,
  setActiveCampaign,
}) => {
  const [campaigns, setCampaigns] = useState([]);
  const [groupCampaigns, setGroupCampaigns] = useState([]);
  const [visibleCampaigns, setVisibleCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [isCampaignGroup, setIsCampaignGroup] = useState(false);
  const [modalType, setModalType] = useState();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [EditGroupModalState, setEditGroupModalState] = useState(false);

  const { showModal, handleShowModal, handleCloseModal } =
    useContext(GeneralModalContext);

  const clientId = match.params.id;

  const [currentPageCampaigns, setCurrentPageCampaigns] = useState(1);
  const [totalPagesCampaigns, setTotalPagesCampaigns] = useState(1);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [limitCampaigns] = useState(config.ITEMS_PER_PAGE);

  const [currentPageGroups, setCurrentPageGroups] = useState(1);
  const [totalPagesGroups, setTotalPagesGroups] = useState(1);
  const [totalGroups, setTotalGroups] = useState(0);
  const [limitGroups] = useState(config.ITEMS_PER_PAGE);

  const [groups, setGroups] = useState([]);
  const [visibleGroups, setVisibleGroups] = useState([]);
  const [loadingGroups, setLoadingGroups] = useState(true);

  const [currentClient, setCurrentClient] = useState(null);
  const [loadingClient, setLoadingClient] = useState(true);

  const debouncedFetchCampaigns = useDebounce((searchTerm) => {
    fetchCampaigns(1, searchTerm);
  });

  const fetchCampaigns = async (page = 1, name = '') => {
    setLoadingCampaigns(true);
    try {
      const queryParams = new URLSearchParams({
        page: page.toString(),
        limit: limitCampaigns.toString(),
        clientId,
        groupId: null,
        ...(name && { name }),
      });

      const res = await fetchInstance(`/getCampaigns?${queryParams}`);
      if (!res.ok) throw new Error(res.statusText);

      const data = await res.json();
      if (!data.error) {
        setCampaigns(data.campaigns);
        setVisibleCampaigns(data.campaigns);
        setTotalPagesCampaigns(data.totalPages);
        setTotalCampaigns(data.totalCampaigns);
        setCurrentPageCampaigns(page);
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      setBanner(true, 'bad', err.message);
    } finally {
      setLoadingCampaigns(false);
    }
  };

  const fetchGroups = async (page = 1, name = '') => {
    setLoadingGroups(true);
    try {
      const queryParams = new URLSearchParams({
        page: page.toString(),
        limit: limitGroups.toString(),
        ...(name && { name }),
      });

      const res = await fetchInstance(`/getGroups/${clientId}?${queryParams}`);
      if (!res.ok) throw new Error(res.statusText);
      const data = await res.json();

      if (data.error) throw new Error(data.message);
      setGroups(data.groups);
      setVisibleGroups(data.groups);
      setTotalPagesGroups(data.totalPages);
      setTotalGroups(data.totalGroups);
      setCurrentPageGroups(page);
    } catch (err) {
      setBanner(true, 'bad', err.message);
    } finally {
      setLoadingGroups(false);
    }
  };

  const debouncedFetchGroups = useDebounce((searchTerm) => {
    fetchGroups(1, searchTerm);
  });

  const fetchCurrentClient = async () => {
    setLoadingClient(true);
    try {
      const res = await fetchInstance(`/getClient/${clientId}`);
      if (!res.ok) throw new Error(res.statusText);

      const data = await res.json();

      setCurrentClient(data);
    } catch (err) {
      console.error('Error fetching client:', err);
      setBanner(true, 'bad', err.message);
    } finally {
      setLoadingClient(false);
    }
  };

  useEffect(() => {
    fetchCurrentClient();
    fetchCampaigns(currentPageCampaigns);
    fetchGroups(currentPageGroups);
  }, [clientId]);

  const handleCampaignPageChange = (page) => {
    fetchCampaigns(page);
  };

  const handleGroupPageChange = (page) => {
    fetchGroups(page);
  };

  const handleSearchGroupInput = (ev) => {
    const searchTerm = ev.target.value.toLowerCase();
    setCurrentPageGroups(1);
    debouncedFetchGroups(searchTerm);
  };

  const deleteCampaign = (ev) => {
    const campaign = { ...selectedCampaign };
    fetchInstance(`/deleteCampaign/${campaign._id}`, {
      method: 'delete',
      body: JSON.stringify({ campaign }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(res.status + ' ' + res.statusText);
      })
      .then((data) => {
        if (data.error) throw new Error(data.message);

        let oldCampaigns = [...campaigns];
        oldCampaigns = oldCampaigns.filter((current) => {
          if (current._id === campaign._id) return false;
          return current;
        });

        handleCloseModal();

        setCampaigns(oldCampaigns);
        setVisibleCampaigns(oldCampaigns);
        setSelectedCampaign(null);
      })
      .catch((err) => setBanner(true, 'bad', err.message, false));
  };

  const updateCampaign = async (updatedCampaign) => {
    try {
      const { _id, ...campaignData } = updatedCampaign;

      if (campaignData.startDate) {
        campaignData.startDate = new Date(campaignData.startDate).toISOString();
      }
      if (campaignData.endDate) {
        campaignData.endDate = new Date(campaignData.endDate).toISOString();
      }

      const finalClientId =
        campaignData.group?.client?._id ||
        campaignData.client?._id ||
        match.params.id;

      if (!finalClientId) {
        throw new Error('Client ID is required');
      }

      const requestBody = {
        campaign: {
          ...campaignData,
          client: finalClientId,
          group: campaignData.group?._id || campaignData.group || null,
        },
      };

      const endpoint = _id ? `/updateCampaign/${_id}` : '/campaign';
      const method = _id ? 'PUT' : 'POST';

      const res = await fetchInstance(endpoint, {
        method,
        body: JSON.stringify(requestBody),
      });

      if (!res.ok) throw new Error('Failed to save campaign');

      const responseData = await res.json();
      await fetchCampaigns(currentPageCampaigns);
      setSelectedCampaign(null);
      handleCloseModal();
      setBanner(
        true,
        'good',
        _id ? 'Campaign updated successfully' : 'Campaign created successfully',
      );
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  const updateGroupName = async (groupId, newName) => {
    try {
      const res = await fetchInstance(`/updateGroup/${groupId}`, {
        method: 'PUT',
        body: JSON.stringify({
          group: {
            name: newName,
          },
        }),
      });

      if (!res.ok) throw new Error(res.statusText);
      const data = await res.json();

      if (data.error) throw new Error(data.message);

      await fetchGroups();
      setBanner(true, 'good', 'Group name updated successfully');
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  const handleSearchCampaignInput = (ev) => {
    const searchTerm = ev.target.value.toLowerCase();
    setCurrentPageCampaigns(1);
    debouncedFetchCampaigns(searchTerm);
  };

  const openCampaignModal = (campaign, isGroup = false) => {
    setIsCampaignGroup(isGroup);
    setSelectedCampaign(campaign);
    setModalType('campaign-modal');
    handleShowModal();
  };

  const openShareModal = (campaign) => {
    setSelectedCampaign(campaign);
    setModalType('share-modal');
    handleShowModal();
  };

  const openDeleteModal = (campaign) => {
    setSelectedCampaign(campaign);
    setModalType('delete-campaign');
    handleShowModal();
  };

  const openNotesModal = (campaign) => {
    setSelectedCampaign(campaign);
    setModalType('show-notes');
    handleShowModal();
  };

  const openEditModal = (group) => {
    setSelectedGroup(group);
    setModalType('edit-group');
    handleShowModal();
  };

  const openCreateGroupModal = () => {
    setModalType('create-group');
    handleShowModal();
  };

  const deleteGroup = async (group) => {
    try {
      const res = await fetchInstance(`/deleteGroup/${group._id}`, {
        method: 'DELETE',
      });

      if (!res.ok) throw new Error(res.statusText);
      const data = await res.json();

      if (data.error) throw new Error(data.message);

      const updatedGroups = groups.filter((g) => g._id !== group._id);
      setGroups(updatedGroups);
      setVisibleGroups(updatedGroups);
      handleCloseModal();
      setBanner(true, 'good', 'Group deleted successfully');
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  const openDeleteGroupModal = (group) => {
    setSelectedGroup(group);
    setModalType('delete-group');
    handleShowModal();
  };

  let shareModal,
    editModal,
    DeleteModalUx,
    ShowNotesModal,
    editGroupModal,
    createGroupModal;

  if (showModal && modalType === 'campaign-modal') {
    editModal = (
      <CampaignDetails
        extractCSRF={extractCSRF}
        selectedCampaign={selectedCampaign}
        handleCloseModal={handleCloseModal}
        updateCampaign={updateCampaign}
        setBanner={setBanner}
        showModal={showModal}
        clientId={clientId}
        userId={user._id}
        isCampaignGroup={
          selectedCampaign ? selectedCampaign.isCampaignGroup : isCampaignGroup
        }
      />
    );
  }

  if (showModal && modalType === 'share-modal') {
    shareModal = (
      <ShareCampaign
        extractCSRF={extractCSRF}
        selectedCampaign={selectedCampaign}
        handleCloseModal={handleCloseModal}
        showModal={showModal}
        setBanner={setBanner}
        updateCampaign={updateCampaign}
      />
    );
  }

  if (showModal && modalType === 'delete-campaign') {
    DeleteModalUx = (
      <DeleteModal
        acceptFunction={deleteCampaign}
        showModal={showModal}
        message={[
          'Do you really want to delete ',
          <b>{selectedCampaign.name}</b>,
          '?',
        ]}
        handleCloseModal={handleCloseModal}
      />
    );
  }

  if (showModal && modalType === 'show-notes') {
    ShowNotesModal = (
      <GeneralModalStyles className="modal-wrapper">
        <div className="modal-body" style={{ width: '550px' }}>
          <div onClick={handleCloseModal} className="modal-close">
            <MdClose className="action-button button-delete" />
          </div>

          <div style={{ padding: '40px 10px 20px' }}>
            {selectedCampaign.notes}
          </div>
        </div>
      </GeneralModalStyles>
    );
  }

  if (
    showModal &&
    (modalType === 'edit-group' || modalType === 'create-group')
  ) {
    editGroupModal = (
      <GroupModal
        group={modalType === 'edit-group' ? selectedGroup : null}
        handleCloseModal={handleCloseModal}
        setBanner={setBanner}
        updateGroupName={updateGroupName}
        clientId={clientId}
        onGroupCreated={async (newGroup) => {
          try {
            await fetchGroups(currentPageGroups);
            handleCloseModal();
          } catch (err) {
            setBanner(true, 'bad', err.message);
          }
        }}
      />
    );
  }

  if (showModal && modalType === 'delete-group') {
    DeleteModalUx = (
      <DeleteModal
        acceptFunction={() => deleteGroup(selectedGroup)}
        showModal={showModal}
        message={[
          'Do you really want to delete group ',
          <b>{selectedGroup.name}</b>,
          '?',
        ]}
        handleCloseModal={handleCloseModal}
      />
    );
  }

  return (
    <DefaultLayout>
      <FlexContainer
        direction="column"
        gap="30px"
        justifyContent="space-between"
      >
        <div>
          <div>
            <Link
              to={`/clients`}
              style={{ border: '0', fontSize: '20px', fontWeight: 'bold' }}
            >
              <IoArrowBack style={{ transform: 'translateY(3px)' }} />
              Back
            </Link>
          </div>
          {currentClient && (
            <>
              <Title style={{ marginBottom: '0' }}>{currentClient?.name}</Title>
              {currentClient?.notes && (
                <div style={{ marginTop: '10px' }}>{currentClient?.notes}</div>
              )}
            </>
          )}
        </div>
        <FlexContainer
          direction="column"
          gap="20px"
          justifyContent="space-between"
        >
          <FlexContainer direction="row" justifyContent="space-between">
            <Title style={{ fontSize: '20px', marginBottom: '0' }}>
              Groups ({totalGroups})
            </Title>
            <GroupCampaignSearchBar
              handleSearchCampaignInput={handleSearchGroupInput}
              openCampaignModal={openCreateGroupModal}
            />
          </FlexContainer>

          <GridContainer columns="repeat(4, 1fr)" gap="20px">
            {visibleGroups.length !== 0 &&
              visibleGroups.map((group) => (
                <GroupCard
                  key={group._id}
                  dataId={group._id}
                  group={group}
                  openEditModal={() => openEditModal(group)}
                  openDeleteModal={() => openDeleteGroupModal(group)}
                  openNotesModal={openNotesModal}
                />
              ))}
            {!loadingGroups && visibleGroups.length === 0 && (
              <p
                style={{
                  fontSize: '14px',
                  lineHeight: '19px',
                  color: '#8d8d8d',
                }}
              >
                No groups found
              </p>
            )}
          </GridContainer>

          {totalPagesGroups > 1 && (
            <Pagination
              currentPage={currentPageGroups}
              totalPages={totalPagesGroups}
              onPageChange={handleGroupPageChange}
            />
          )}
        </FlexContainer>

        <FlexContainer
          direction="column"
          gap="20px"
          justifyContent="space-between"
        >
          <FlexContainer direction="row" justifyContent="space-between">
            <Title style={{ fontSize: '20px', marginBottom: '0' }}>
              Campaigns ({totalCampaigns})
            </Title>
            <CampaignSearchBar
              handleSearchCampaignInput={handleSearchCampaignInput}
              openCampaignModal={openCampaignModal}
            />
          </FlexContainer>

          <GridContainer columns="repeat(4, 1fr)" gap="20px">
            {visibleCampaigns.length !== 0 &&
              visibleCampaigns.map((campaign) => (
                <CampaignCard
                  key={campaign._id}
                  campaign={campaign}
                  openShareModal={openShareModal}
                  openEditModal={openCampaignModal}
                  openDeleteModal={openDeleteModal}
                  openNotesModal={openNotesModal}
                />
              ))}
            {!loadingCampaigns && visibleCampaigns.length === 0 && (
              <p
                style={{
                  fontSize: '14px',
                  lineHeight: '19px',
                  color: '#8d8d8d',
                }}
              >
                No campaigns found
              </p>
            )}
          </GridContainer>

          {totalPagesCampaigns > 1 && (
            <Pagination
              currentPage={currentPageCampaigns}
              totalPages={totalPagesCampaigns}
              onPageChange={handleCampaignPageChange}
            />
          )}
        </FlexContainer>

        {DeleteModalUx}
        {editModal}
        {shareModal}
        {ShowNotesModal}
        {editGroupModal}
        {createGroupModal}
      </FlexContainer>
    </DefaultLayout>
  );
};

export default CampaignsMain;

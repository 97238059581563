import { TextItemDefaults } from './Defaults';
import getDefaultFrame from './GetDefaultFrame';

const normalizeVariations = (variations, resolutions, type, index = 0) => {
  const normalizedVariations = { ...variations };

  console.log('initialVariations: ', variations);

  resolutions.forEach((resolution) => {
    if (!normalizedVariations[resolution]) {
      // Dacă rezoluția lipsește complet, o adăugăm ca disabled
      normalizedVariations[resolution] = {
        status: 'disabled',
        options: {},
        frame: getDefaultFrame({ zIndex: index }),
        locked: false,
      };
    } else {
      // Dacă rezoluția există, ne asigurăm că are toate proprietățile necesare
      const variation = normalizedVariations[resolution];

      // Setăm valorile implicite în funcție de status
      if (variation.status === 'disabled') {
        normalizedVariations[resolution] = {
          status: 'disabled',
          options: {},
          frame: getDefaultFrame({ zIndex: index }),
          locked: false,
        };
      } else {
        // Pentru variațiile enabled, păstrăm datele existente și adăugăm ce lipsește
        normalizedVariations[resolution] = {
          status: 'enabled',
          options: {
            ...(type === 'BannerTextItem' ? TextItemDefaults : {}),
            ...variation.options,
          },
          frame: {
            ...getDefaultFrame({ zIndex: index }),
            ...(variation.frame || {}),
          },
          locked: variation.locked || false,
        };
      }
    }
  });

  console.log('normalizedVariations: ', normalizedVariations);

  return normalizedVariations;
};

export default normalizeVariations;

import React from 'react';
import { Search } from 'react-bootstrap-icons';
import { useHistory, useParams } from 'react-router-dom';
import { ClientSearchBarStyle } from '../../Components/styles/ClientSearchBarStyle';
import { ReactComponent as NewBannerIcon } from '../../images/new_banner.svg';

const BannerSetSearchBar = ({ handleSearchBannerSetInput, value }) => {
  const history = useHistory();
  const { id: campaignId } = useParams();

  const handleNewBannerSet = () => {
    history.push(`/banner-creator?cid=${campaignId}`);
  };

  return (
    <ClientSearchBarStyle style={{ textAlign: 'right', gap: '10px' }}>
      <span className="search-client-wrapper">
        <Search />
        <input
          onChange={handleSearchBannerSetInput}
          value={value}
          id="search-client"
          type="text"
          placeholder="Search banner sets"
        />
      </span>
      <div className="filter-add-client">
        <button className="confirm-button" onClick={handleNewBannerSet}>
          <NewBannerIcon width={16} height={16} />
          New banner set
        </button>
      </div>
    </ClientSearchBarStyle>
  );
};

export default BannerSetSearchBar;

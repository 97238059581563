import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'wrap'};
  gap: ${({ gap }) => gap || '20px'};
  align-content: ${({ alignContent }) => alignContent || 'stretch'};

  @media (max-width: 768px) {
    flex-direction: ${({ mobileDirection }) => mobileDirection || 'column'};
    justify-content: ${({ mobileJustifyContent }) =>
      mobileJustifyContent || 'center'};
    align-items: ${({ mobileAlignItems }) => mobileAlignItems || 'center'};
    flex-wrap: ${({ mobileFlexWrap }) => mobileFlexWrap || 'wrap'};
    gap: ${({ mobileGap }) => mobileGap || '20px'};
    align-content: ${({ mobileAlignContent }) =>
      mobileAlignContent || 'stretch'};
  }
`;

import React from 'react';
import cx from 'classnames';
import styles from './ContentSection.module.css';
import ColorPickerBox from '../ColorPickerBox';
import Input from '../../../../../FormComponents/Input';
import Select from '../../../../../FormComponents/Select';
import FileUploader from '../../../../../FileUploader/FileUploader';

const ContentSection = ({
  currentOptions,
  fontsList,
  fontSizes,
  onFontChange,
  onSizeChange,
  colorProps,
  content,
  onChange,
  error,
  isDisabled,
  textTags,
  onTagChange,
  onPositionChange,
  textPositions,
  setCustomFont,
}) => {
  const tags = textTags.map((tag) => ({
    label: tag,
    value: tag,
  }));

  const positions = textPositions.map((position) => ({
    label: position,
    value: position.toLowerCase(),
  }));

  const sizes = fontSizes.map((size) => ({
    label: size,
    value: size,
  }));

  const fonts = [
    ...Object.keys(fontsList).map((font) => ({
      label: font,
      value: font,
    })),
    {
      label: 'Upload Font',
      value: 'Custom',
    },
  ];

  return (
    <div className={cx(styles.root, isDisabled && styles.disabled)}>
      <Input
        label="Content"
        value={content || ''}
        onChange={onChange}
        required
        isTextarea
        textareaProps={{ rows: 2 }}
        error={error}
      />
      <div className={styles.sectionTitle}>Styling</div>
      <div className={styles.fontSection}>
        <Select
          label="Font family"
          options={fonts}
          value={currentOptions?.font || ''}
          onChange={onFontChange}
        />
        <Select
          label="Font size"
          options={sizes}
          value={currentOptions?.size || '20px'}
          onChange={onSizeChange}
        />
        <ColorPickerBox {...colorProps} />
      </div>
      {currentOptions?.font === 'Custom' && (
        <div className="item-display">
          <FileUploader
            multiple={true}
            preview={false}
            onChange={setCustomFont}
            label="Upload font"
            persistent="true"
            extensions=".ttf,.otf,.woff"
          />
        </div>
      )}
      <div className={styles.doubleSection}>
        <Select
          label="Text tag"
          options={tags}
          value={currentOptions?.tag || ''}
          onChange={onTagChange}
        />
        <Select
          label="Text position"
          options={positions}
          value={currentOptions?.position || ''}
          onChange={onPositionChange}
        />
      </div>
    </div>
  );
};

export default ContentSection;

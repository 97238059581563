import React, { useState } from 'react';
import { CloudUpload, Ban, Shuffle, Arrow90degUp } from 'react-bootstrap-icons';
import cx from 'classnames';
import { Dispatcher, Events } from '../../../../Events';
import FileUploader from '../../../../FileUploader/FileUploader';
import styles from './BannerImageOptions.module.css';
import Button from '../../../../FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../shared/enums/buttonEnum';

const BannerImageOptions = ({ closeModal, resolutions, edit, variations, id }) => {
  const [uploads, setUploads] = useState(() => {
    if (edit && variations) {
      const initialUploads = {};
      Object.entries(variations).forEach(([resolution, data]) => {
        if (data.status === 'enabled' && data.options.src) {
          initialUploads[resolution] = {
            file: {
              path: data.options.src,
              dimensions: data.options.dimensions || {
                width: data.frame.width,
                height: data.frame.height,
              },
            },
          };
        }
      });
      return initialUploads;
    }
    return {};
  });

  const [error, setError] = useState(null);
  const [errorResolutions, setErrorResolutions] = useState([]);
  const [disabledResolutions, setDisabledResolutions] = useState(() => {
    if (edit && variations) {
      return Object.entries(variations)
        .filter(([_, data]) => data.status === 'disabled')
        .map(([resolution]) => resolution);
    }
    return [];
  });
  const [mainResolution, setMainResolution] = useState(resolutions[0]);

  const handleFileChange = (resolution) => (uploader, e) => {
    // console.log('HandleFileChange: Resolution', resolution);
    // console.log('HandleFileChange: Uploader', uploader);
    setUploads((prev) => ({
      ...prev,
      [resolution]: {
        file: uploader.uploads[0],
        uploader,
      },
    }));

    setErrorResolutions((prev) => {
      const updatedErrorResolutions = prev.filter((res) => res !== resolution);

      if (updatedErrorResolutions.length > 0) {
        setError(
          `Please upload images for all resolutions. Missing: ${updatedErrorResolutions.join(
            ', ',
          )}`,
        );
      } else {
        setError(null);
      }

      return updatedErrorResolutions;
    });
  };

  const handleDisable = (resolution) => {
    setDisabledResolutions((prev) => {
      const isCurrentlyDisabled = prev.includes(resolution);

      if (isCurrentlyDisabled) {
        return prev.filter((res) => res !== resolution);
      } else {
        if (uploads[resolution]) {
          setUploads((prevUploads) => {
            const { [resolution]: removed, ...rest } = prevUploads;
            return rest;
          });
        }
        setErrorResolutions((current) =>
          current.filter((res) => res !== resolution),
        );
        return [...prev, resolution];
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log('HandleSubmit: Uploads', uploads);

    const missingResolutions = resolutions
      .filter((res) => !disabledResolutions.includes(res))
      .filter((res) => !uploads[res]);

    if (missingResolutions.length > 0) {
      setErrorResolutions(missingResolutions);
      setError(
        `Please upload images for all enabled resolutions. Missing: ${missingResolutions.join(
          ', ',
        )}`,
      );
      return;
    }

    // Creăm variațiile pentru toate rezoluțiile
    const variations = {};
    // console.log('HandleSubmit: Resolutions', resolutions);

    resolutions.forEach((resolution) => {
      // console.log('HandleSubmit: Resolution', resolution);

      if (disabledResolutions.includes(resolution)) {
        // Pentru rezoluțiile disabled
        variations[resolution] = {
          status: 'disabled',
          options: {},
          frame: {
            width: '0px',
            height: '0px',
            left: '0px',
            top: '0px',
            transform: {
              rotate: '0deg',
              scaleX: 1,
              scaleY: 1,
              matrix3d: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
              translate: [0, 0],
            },
          },
        };
      } else if (uploads[resolution]) {
        // Pentru rezoluțiile enabled cu upload
        variations[resolution] = {
          status: 'enabled',
          options: {
            src: uploads[resolution].file.path,
            dimensions: uploads[resolution].file.dimensions,
          },
          frame:
            edit && variations[resolution]?.frame
              ? variations[resolution].frame // Păstrăm frame-ul existent în modul edit
              : {
                  width: uploads[resolution].file.dimensions.width,
                  height: uploads[resolution].file.dimensions.height,
                  left: '0px',
                  top: '0px',
                  transform: {
                    rotate: '0deg',
                    scaleX: 1,
                    scaleY: 1,
                    matrix3d: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
                    translate: [0, 0],
                  },
                },
        };
      }
    });

    console.log('variations submitted:', variations);

    Dispatcher.dispatch({
      event: edit ? Events.UPDATE_BANNER_ELEMENT : Events.ADD_BANNER_ELEMENT,
      payload: {
        type: 'BannerImageItem',
        variations,
        id: edit ? id : undefined,
      },
    });

    closeModal();
  };

  const handleCopyToAll = () => {
    if (!uploads[mainResolution]) return;

    const mainUpload = uploads[mainResolution];
    const updatedUploads = {};

    resolutions.forEach((resolution) => {
      if (!disabledResolutions.includes(resolution)) {
        updatedUploads[resolution] = {
          file: mainUpload.file,
          uploader: mainUpload.uploader,
        };
      }
    });

    setUploads((prev) => ({
      ...prev,
      ...updatedUploads,
    }));

    // Curățăm erorile pentru rezoluțiile care au primit imagine
    setErrorResolutions((prev) =>
      prev.filter((res) => disabledResolutions.includes(res)),
    );
    setError(null);
  };

  const handleCopyFromMain = (targetResolution) => {
    if (!uploads[mainResolution]) return;

    setUploads((prev) => ({
      ...prev,
      [targetResolution]: {
        file: uploads[mainResolution].file,
        uploader: uploads[mainResolution].uploader,
      },
    }));

    // Curățăm eroarea pentru această rezoluție
    setErrorResolutions((prev) =>
      prev.filter((res) => res !== targetResolution),
    );
    if (errorResolutions.length <= 1) {
      setError(null);
    }
  };

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit}>
        <div className={styles.uploadsGrid}>
          {resolutions.map((resolution, index) => {
            const hasUpload = uploads[resolution]?.file;
            const hasError = errorResolutions.includes(resolution);
            const isDisabled = disabledResolutions.includes(resolution);
            const isMainResolution = resolution === mainResolution;
            const showCopyButtons = resolutions.length > 1;

            return (
              <div key={resolution} className={styles.uploadWrapper}>
                <div
                  className={cx(
                    styles.inner,
                    hasError && styles.hasError,
                    isDisabled && styles.isDisabled,
                    isMainResolution && styles.mainResolution,
                  )}
                >
                  <div className={styles.resolutionWrapper}>
                    <span className={styles.resolutionLabel}>{resolution}</span>
                    <button
                      type="button"
                      className={styles.disableButton}
                      onClick={() => handleDisable(resolution)}
                    >
                      {isDisabled ? 'Disabled' : 'Disable'} <Ban />
                    </button>
                  </div>
                  <div className={styles.uploadBox}>
                    <div
                      className={cx(styles.uploadContent, {
                        [styles.hasImage]: hasUpload,
                      })}
                    >
                      {hasUpload ? (
                        <img
                          src={uploads[resolution].file.path}
                          alt={`Image ${resolution}`}
                        />
                      ) : (
                        <CloudUpload size={24} />
                      )}
                      <FileUploader
                        className={styles.fileUploader}
                        onChange={handleFileChange(resolution)}
                        inputClassName={styles.fileUploaderInput}
                        uploads={[]}
                        preview="false"
                      />
                    </div>
                  </div>
                  {showCopyButtons && (
                    <div className={styles.copyButtons}>
                      {isMainResolution ? (
                        <Button
                          type="button"
                          variant={ButtonVariantEnum.TEXT}
                          onClick={handleCopyToAll}
                          disabled={!hasUpload}
                          size={ButtonSizeEnum.XS}
                          leftIcon={<Shuffle />}
                        >
                          Copy to all resolutions
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          variant={ButtonVariantEnum.TEXT}
                          onClick={() => handleCopyFromMain(resolution)}
                          disabled={!uploads[mainResolution]}
                          size={ButtonSizeEnum.XS}
                          leftIcon={
                            <Arrow90degUp className={styles.reflectIcon} />
                          }
                        >
                          Copy from main
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {error && <div className={styles.error}>{error}</div>}

        <div className={styles.actions}>
          <Button
            type="submit"
            variant={ButtonVariantEnum.PRIMARY}
            className={styles.actionButton}
          >
            {edit ? 'Update' : 'Add'}
          </Button>
          <Button
            type="button"
            variant={ButtonVariantEnum.SECONDARY}
            onClick={closeModal}
            className={styles.actionButton}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BannerImageOptions;

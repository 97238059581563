import { defaultTransform } from './Defaults';

const getDefaultFrame = ({ zIndex, width = '100px', height = '100px' }) => ({
  width,
  height,
  left: '0px',
  top: '0px',
  transform: defaultTransform,
  ...(zIndex ? { 'z-index': zIndex } : {}),
});

export default getDefaultFrame;

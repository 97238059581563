import React, { Component } from 'react';
import { AspectRatioFill } from 'react-bootstrap-icons';

import Button from '../../../../FormComponents/Button';
import BannerBackgroundOptions from './BannerBackgroundOptions';
import { ButtonSizeEnum } from '../../../../../shared/enums/buttonEnum';
import Modal from '../../../../Common/Modal/Modal';

class BannerBackgroundButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { state, handleOpen, handleClose } = this;
    const { resolutions, currentBackgrounds } = this.props;

    return (
      <>
        <Button
          onClick={handleOpen}
          leftIcon={<AspectRatioFill />}
          size={ButtonSizeEnum.S}
        >
          Background
        </Button>

        <Modal
          title="Set background image"
          isOpen={state.open}
          onClose={handleClose}
        >
          <BannerBackgroundOptions
            closeModal={handleClose}
            resolutions={resolutions}
            existingBackgrounds={currentBackgrounds}
          />
        </Modal>
      </>
    );
  }
}

export default BannerBackgroundButton;

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Trash3,
  PencilSquare,
  Diagram2,
  CalendarRange,
  Globe,
  FolderFill,
} from 'react-bootstrap-icons';

import styles from './GroupCard.module.scss';

const GroupCard = ({
  group,
  openEditModal,
  openDeleteModal,
  openNotesModal,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.settings}>
        <button
          onClick={() => openEditModal(group)}
          className={styles.settingsButton}
        >
          <PencilSquare />
        </button>
        <button
          onClick={() => openDeleteModal(group)}
          className={styles.settingsButton}
        >
          <Trash3 />
        </button>
      </div>
      <div className={styles.networks}>
        <FolderFill color="#2B3990" width="22px" height="22px" />
      </div>
      <Link to={`/groups/${group._id}`} className={styles.cardLink}>
        <div className={styles.details}>
          <h3>{group.name}</h3>
        </div>
        <div className={styles.detailsBottom}>
          <div className={styles.campaigns}>
            <Diagram2 width="16px" height="16px" />
            <p>{group.campaignCount} campaigns</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default GroupCard;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1240px;
  height: inherit;
  padding: 2rem 20px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export default Container;

export const defaultTransform = {
  rotate: '0deg',
  scaleX: 1,
  scaleY: 1,
  matrix3d: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
  translate: [0, 0],
};

export const BannerElementDefaults = {
  width: '150px',
  height: '150px',
  left: '0px',
  top: '0px',
  transform: defaultTransform,
};

export const TextItemDefaults = {
  content: '',
  font: 'Arial',
  size: '20px',
  tag: 'span',
  color: '#000',
  class: '',
  position: 'left',
  style: '',
};

import React, { Component } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

class ColorPickerBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      color: props.color ? props.color : '#000',
    };
  }

  handleChange = (color, event) => {
    this.setState({ color: color.hex });
    this.props.onChangeComplete(color, event);
  };

  render() {
    const { state, props, handleChange } = this;

    const styles = reactCSS({
      default: {
        color: {
          width: '100%',
          height: '100%',
          borderRadius: '2px',
          background: `${this.state.color}`,
        },
        swatch: {
          padding: '4px',
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          width: '33px',
          height: '33px',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
        label: {
          marginBottom: '5px',
          fontWeight: 400,
          fontSize: '14px',
          color: '#000',
        },
      },
    });

    return (
      <div>
        {props.label && <div style={styles.label}>{props.label}</div>}
        <div style={styles.swatch} onClick={props.handleClick}>
          <div style={styles.color} />
        </div>

        {props.displayColor ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={props.handleClose} />
            <SketchPicker
              color={state.color}
              onChange={handleChange}
              disableAlpha={true}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPickerBox;

import React, { useState, useEffect } from 'react';
import { XCircleFill } from 'react-bootstrap-icons';
import GeneralModalStyles from '../../Components/styles/GeneralModalStyles';
import FormStyles from '../../Components/styles/FormStyles';
import FlexContainer from '../../Components/styles/FlexContainer';
import fetchInstance from '../../utils/fetchInstance';

const GroupModal = ({
  group,
  handleCloseModal,
  setBanner,
  updateGroupName,
  clientId,
  onGroupCreated,
}) => {
  const [groupData, setGroupData] = useState(null);
  const [newName, setNewName] = useState(group?.name || '');
  const [loading, setLoading] = useState(group ? true : false);
  const isEdit = Boolean(group);

  useEffect(() => {
    if (!group?._id) return;

    const fetchGroupData = async () => {
      try {
        const res = await fetchInstance(`/getGroup/${group._id}`);
        if (!res.ok) throw new Error(res.statusText);

        const data = await res.json();
        if (data.error) throw new Error(data.message);

        setGroupData(data);
        setNewName(data.name);
      } catch (err) {
        setBanner(true, 'bad', err.message);
        handleCloseModal();
      } finally {
        setLoading(false);
      }
    };

    fetchGroupData();
  }, [group?._id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newName.trim() === '') {
      return setBanner(true, 'bad', 'Group name cannot be empty');
    }

    if (newName === 'Rejected' || newName === 'Ungrouped') {
      return setBanner(true, 'bad', 'Name already used!');
    }

    try {
      if (isEdit) {
        if (newName.trim() === groupData.name) {
          return handleCloseModal();
        }
        await updateGroupName(groupData._id, newName.trim());
      } else {
        const res = await fetchInstance('/group', {
          method: 'POST',
          body: JSON.stringify({
            group: {
              name: newName.trim(),
              client: clientId,
            },
          }),
        });

        if (!res.ok) throw new Error(res.statusText);
        const data = await res.json();
        if (data.error) throw new Error(data.message);

        onGroupCreated(data);
      }
      handleCloseModal();
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  if (loading) {
    return (
      <GeneralModalStyles className="modal-wrapper">
        <div className="modal-body" style={{ width: '350px' }}>
          <button
            type="button"
            onClick={handleCloseModal}
            className="modal-close-button"
          >
            <XCircleFill color="black" width={34} height={34} />
          </button>
          <div style={{ textAlign: 'center', padding: '20px' }}>Loading...</div>
        </div>
      </GeneralModalStyles>
    );
  }

  return (
    <GeneralModalStyles className="modal-wrapper">
      <div className="modal-body" style={{ width: '350px' }}>
        <button
          type="button"
          onClick={handleCloseModal}
          className="modal-close-button"
        >
          <XCircleFill color="black" width={34} height={34} />
        </button>

        <FormStyles onSubmit={handleSubmit}>
          <FlexContainer direction="column" gap="40px" alignItems="center">
            <div className="modal-title">
              {isEdit ? 'Edit' : 'Create'} Group
            </div>

            <div className="input-wrapper" style={{ width: '100%' }}>
              <label>Name</label>
              <input
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                placeholder="Enter group name"
                autoFocus
              />
            </div>

            <div className="button-wrapper">
              <button type="submit" className="confirm-button">
                {isEdit ? 'Save' : 'Create'}
              </button>
            </div>
          </FlexContainer>
        </FormStyles>
      </div>
    </GeneralModalStyles>
  );
};

export default GroupModal;

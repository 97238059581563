import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Button.module.css';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
  ButtonTypeEnum,
} from '../../shared/enums/buttonEnum';

/**
 * @typedef {Object} ButtonProps
 * @property {React.ReactNode} children - Content inside the button
 * @property {keyof typeof ButtonTypeEnum} [type=ButtonTypeEnum.BUTTON] - HTML button type
 * @property {React.ReactNode} [rightIcon] - Icon to display on the right
 * @property {React.ReactNode} [leftIcon] - Icon to display on the left
 * @property {() => void} [onClick] - Click handler function
 * @property {keyof typeof ButtonSizeEnum} [size=ButtonSizeEnum.M] - Button size
 * @property {keyof typeof ButtonVariantEnum} [variant=ButtonVariantEnum.PRIMARY] - Button variant/style
 * @property {string} [className] - Additional CSS class names
 * @property {boolean} [disabled=false] - Whether the button is disabled
 */

/**
 * Button component with various sizes, variants and icon support
 * @param {ButtonProps} props
 * @returns {React.ReactElement}
 */
const Button = ({
  children,
  type = ButtonTypeEnum.BUTTON,
  rightIcon,
  leftIcon,
  onClick,
  size = ButtonSizeEnum.M,
  variant = ButtonVariantEnum.PRIMARY,
  className,
  disabled = false,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={cx(styles.button, styles[variant], styles[size], className)}
      disabled={disabled}
    >
      {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
      {children}
      {rightIcon && <span className={styles.rightIcon}>{rightIcon}</span>}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(ButtonTypeEnum)),
  rightIcon: PropTypes.node,
  leftIcon: PropTypes.node,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(ButtonVariantEnum)),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(ButtonSizeEnum)),
};

/**
 * @type {ButtonProps} defaultProps
 */
Button.defaultProps = {
  type: ButtonTypeEnum.BUTTON,
  size: ButtonSizeEnum.M,
  variant: ButtonVariantEnum.PRIMARY,
  disabled: false,
};

export default Button;

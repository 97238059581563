import React from 'react';
import { Link } from 'react-router-dom';
import { Trash3, PencilSquare, Copy, ColumnsGap } from 'react-bootstrap-icons';
import { ReactComponent as ResolutionsIcon } from '../../images/resolutions.svg';
import formatDateWithPastWeek from '../../utils/formatDateWithPastWeek';
import FlexContainer from '../../Components/styles/FlexContainer';
import SocialIcon from '../SocialIcon/SocialIcon';
import styles from './BannerSetCard.module.scss';
import NetworkEnum from '../../shared/enums/networkEnum';
import placeholderImage from '../../images/placeholder_banner_set.svg';

const BannerSetCard = ({
  bannerSet,
  openBannerSetModal,
  handleDuplicate,
  openDeleteModal,
}) => {
  if (!bannerSet) return null;

  const {
    name = '',
    status,
    createdAt,
    resolutions = [],
    nrBanners = [],
    featuredImage,
    platform,
  } = bannerSet;

  const handleEdit = () => {
    openBannerSetModal(bannerSet);
  };

  return (
    <div className={styles.root}>
      <FlexContainer gap="10px" className={styles.settings}>
        <button onClick={handleEdit} className={styles.settingsButton}>
          <PencilSquare width={12} height={12} />
        </button>
        <button
          onClick={() => handleDuplicate(bannerSet)}
          className={styles.settingsButton}
        >
          <Copy width={12} height={12} />
        </button>
        <button
          onClick={() => openDeleteModal(bannerSet)}
          className={styles.settingsButton}
        >
          <Trash3 width={12} height={12} />
        </button>
      </FlexContainer>

      <div className={styles.network}>
        <SocialIcon networks={[platform]} className={styles.networkIcon} />
      </div>

      <div className={styles.imageContainer}>
        <img
          src={featuredImage || placeholderImage}
          alt={name}
          className={styles.bannerImage}
        />
      </div>

      <FlexContainer direction="column" gap="5px" className={styles.details}>
        <h3>{name}</h3>

        <FlexContainer direction="row" alignItems="center" gap="5px">
          {status === 'DRAFT' && (
            <span className={styles.draftStatus}>DRAFT</span>
          )}
          <span className={styles.date}>
            {formatDateWithPastWeek(createdAt)}
          </span>
        </FlexContainer>

        <FlexContainer
          direction="column"
          gap="5px"
          className={styles.detailsBottom}
        >
          <FlexContainer
            alignItems="center"
            gap="5px"
            className={styles.metaItem}
          >
            <ResolutionsIcon width={16} height={16} />
            <span>{resolutions.length || 0} resolutions</span>
          </FlexContainer>
          <FlexContainer
            alignItems="center"
            gap="5px"
            className={styles.metaItem}
          >
            <ColumnsGap width={16} height={16} />
            <span>{nrBanners || 0} banners</span>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </div>
  );
};

export default BannerSetCard;

import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid rgba(43, 57, 144, 0.2);
  border-top: 3px solid #2b3990;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  margin: 20px auto;
`;

export default Loader;

import React from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import html2canvas from 'html2canvas';
import BannerTextItem from './Elements/BannerText/BannerTextItem';
import BannerImageItem from './Elements/BannerImage/BannerImageItem';
import { Dispatcher, Events } from '../../Events';
import './Styles/menu.scss';
import './Styles/preview.scss';

const BannerCreatorPreview = ({
  elements,
  background,
  resolution,
  onRemoveElements,
  onRemoveBackground,
  onReset,
  onBringToFront,
  onSendToBack,
}) => {
  const [width, height] = resolution.split('x').map(Number);

  const handleSendToMixer = () => {
    Dispatcher.dispatch({
      event: Events.OPEN_MIXER_MODAL,
      payload: { elements, background, resolution },
    });
  };

  const handleSaveAsPNG = () => {
    let key = this.getBannerSizeKey();
    let element = document.getElementById('banner-view');

    html2canvas(element, {
      useCORS: false,
      imageTimeout: 2000,
      removeContainer: true,
    }).then((canvas) => {
      var a = document.createElement('a');
      a.href = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      a.download = key + '.png';
      a.click();
    });
  };

  return (
    <ContextMenuTrigger
      attributes={{ className: 'context-menu-trigger' }}
      id={'preview_context_holder'}
    >
      <div className="context-menu-trigger">
        <div className="container banner-preview-container">
          <div
            id="banner-view"
            className="banner-preview"
            style={{
              position: 'relative',
              width: `${width}px`,
              height: `${height}px`,
              backgroundSize: '100% 100%',
              margin: '30px auto 0',
              backgroundColor: '#fff',
              backgroundImage: background?.src
                ? `url(${background.src})`
                : 'none',
              border: 'none',
              borderRadius: '5px',
            }}
          >
            {elements.map((element) => {
              if (element?.variations?.[resolution]?.status === 'disabled') {
                return null;
              }

              const Component =
                element.type === 'BannerTextItem'
                  ? BannerTextItem
                  : BannerImageItem;

              return (
                <Component
                  key={element.id}
                  id={element.id}
                  type={element.type}
                  options={element.options}
                  frame={element.frame}
                  locked={element.locked}
                  container={document.querySelector('.banner-preview')}
                  offsetH={height}
                  offsetW={width}
                  zindex={element.frame['z-index']}
                  autosize={element.type === 'BannerTextItem'}
                  variations={element.variations}
                  onBringToFront={onBringToFront}
                  onSendToBack={onSendToBack}
                />
              );
            })}
          </div>

          <ContextMenu id={'preview_context_holder'}>
            <MenuItem onClick={handleSendToMixer}>Send to Mixer</MenuItem>
            <MenuItem onClick={handleSaveAsPNG}>Save as PNG</MenuItem>
            <MenuItem divider />
            <MenuItem onClick={onRemoveElements}>Remove elements</MenuItem>
            <MenuItem onClick={onRemoveBackground}>Remove background</MenuItem>
            <MenuItem divider />
            <MenuItem onClick={onReset}>Reset</MenuItem>
          </ContextMenu>
        </div>
      </div>
    </ContextMenuTrigger>
  );
};

export default BannerCreatorPreview;

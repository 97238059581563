import React, { Component } from 'react';
import { TextareaT } from 'react-bootstrap-icons';

import BannerModal from '../../BannerModal';

import { Dispatcher, Events } from '../../../../Events';
import Button from '../../../../FormComponents/Button';
import { ButtonSizeEnum } from '../../../../../shared/enums/buttonEnum';
import BannerTextModal from './Modal/TextModal';

class BannerTextButton extends Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState = {
      id: null,
      open: false,
      edit: false,
      title: 'Add text element',
      options: undefined,
      variations: undefined,
    };

    Dispatcher.listen(Events.EDIT_BANNER_ELEMENT, this.onEdit.bind(this));
  }

  onEdit = (payload, event) => {
    console.log('payload.variations: ', payload.variations);
    console.log('payload.options: ', payload.options);
    if (payload.type === 'BannerTextItem') {
      this.setState({
        open: true,
        id: payload.id,
        title: 'Edit text ' + payload.id,
        edit: true,
        options: payload.options,
        variations: payload.variations,
      });
    }
  };

  handleOpen = () => {
    this.setState({ ...this.defaultState, ...{ open: true } });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { state } = this;

    const { handleOpen, handleClose } = this;
    const { resolutions } = this.props;

    return (
      <>
        <Button
          onClick={handleOpen}
          leftIcon={<TextareaT />}
          size={ButtonSizeEnum.S}
        >
          Text
        </Button>

        <BannerModal
          title={state.title}
          open={state.open}
          handleClose={handleClose}
        >
          <BannerTextModal
            resolutions={resolutions}
            id={state.id}
            edit={state.edit}
            options={state.options}
            variations={state.variations}
            closeModal={handleClose}
          />
        </BannerModal>
      </>
    );
  }
}

export default BannerTextButton;

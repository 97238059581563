import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './CustomResolutionInput.module.css';
import Input from './Input';

const CustomResolutionInput = ({
  customResolution,
  onCustomResolutionChange,
  onAddCustomResolution,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [errors, setErrors] = useState({
    width: false,
    height: false,
  });

  useEffect(() => {
    if (errors.width || errors.height) {
      const timer = setTimeout(() => {
        setErrors({ width: false, height: false });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errors]);

  const handleSave = () => {
    if (!customResolution.width || !customResolution.height) {
      setErrors({
        width: !customResolution.width,
        height: !customResolution.height,
      });
      return;
    }
    onAddCustomResolution();
    setIsFormVisible(false);
  };

  const handleCancel = () => {
    onCustomResolutionChange({ target: { name: 'width', value: '' } });
    onCustomResolutionChange({ target: { name: 'height', value: '' } });
    setErrors({ width: false, height: false });
    setIsFormVisible(false);
  };

  if (!isFormVisible) {
    return (
      <button
        type="button"
        className={styles.addCustomButton}
        onClick={() => setIsFormVisible(true)}
      >
        + Add custom
      </button>
    );
  }

  return (
    <div className={styles.root}>
      <label className={styles.label}>Custom Resolution</label>
      <div className={styles.inputGroup}>
        <Input
          type="number"
          name="width"
          value={customResolution.width}
          onChange={onCustomResolutionChange}
          placeholder="Width"
          error={errors.width ? ' ' : ''}
          className={errors.width ? styles.errorShake : ''}
        />
        <span className={styles.separator}>x</span>
        <Input
          type="number"
          name="height"
          value={customResolution.height}
          onChange={onCustomResolutionChange}
          placeholder="Height"
          error={errors.height ? ' ' : ''}
          className={errors.height ? styles.errorShake : ''}
        />
        <div className={styles.buttonGroup}>
          <button
            type="button"
            className={styles.saveButton}
            onClick={handleSave}
          >
            Save
          </button>
          <button
            type="button"
            className={styles.cancelButton}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

CustomResolutionInput.propTypes = {
  customResolution: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }).isRequired,
  onCustomResolutionChange: PropTypes.func.isRequired,
  onAddCustomResolution: PropTypes.func.isRequired,
};

export default CustomResolutionInput;

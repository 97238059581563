import React from 'react';
import cx from 'classnames';
import {
  Shuffle,
  ArrowBarDown,
  ChevronDown,
} from 'react-bootstrap-icons';
import styles from './AdvancedSection.module.css';
import { ButtonSizeEnum, ButtonVariantEnum } from '../../../../../../shared/enums/buttonEnum';
import Button from '../../../../../FormComponents/Button';
import Input from '../../../../../FormComponents/Input';

const AdvancedSection = ({
  isOpen,
  onToggle,
  isMainResolution,
  selectedResolution,
  onCopyToAll,
  onCopyFromMain,
  currentOptions,
  onClassChange,
  onStyleChange,
  isDisabled,
}) => (
  <div className={cx(styles.root, isDisabled && styles.disabled)}>
    <div>
      <Button
        variant={ButtonVariantEnum.TEXT}
        onClick={onToggle}
        rightIcon={<ChevronDown />}
        size={ButtonSizeEnum.S}
        className={cx(
          styles.advancedButton,
          isOpen && styles.toggledCustomCss,
        )}
      >
        Advanced options
      </Button>
    </div>
    {isOpen && (
      <>
        <div>
          {isMainResolution && (
            <Button
              type="button"
              variant={ButtonVariantEnum.TEXT}
              onClick={onCopyToAll}
              size={ButtonSizeEnum.XS}
              leftIcon={<Shuffle />}
            >
              Copy to all resolutions
            </Button>
          )}
          {!isMainResolution && (
            <Button
              variant={ButtonVariantEnum.TEXT}
              onClick={() => onCopyFromMain(selectedResolution)}
              size={ButtonSizeEnum.XS}
              leftIcon={<ArrowBarDown />}
            >
              Copy from main
            </Button>
          )}
        </div>
        <Input
          label="Mixer Identifier"
          value={currentOptions?.class || ''}
          onChange={onClassChange}
        />
        <Input
          label="Custom CSS"
          value={currentOptions?.style || ''}
          onChange={onStyleChange}
          isTextarea
          textareaProps={{
            rows: 5,
          }}
        />
      </>
    )}
  </div>
);

export default AdvancedSection;

import React, { useState, useEffect } from 'react';
import FormStyles from '../styles/FormStyles';
import Title from '../styles/TitleStyle';
import { XCircleFill } from 'react-bootstrap-icons';
import GeneralModalStyles from '../styles/GeneralModalStyles';
import fetchInstance from '../../utils/fetchInstance';
import FlexContainer from '../styles/FlexContainer';

const ShareCampaign = ({
  selectedCampaign,
  handleCloseModal,
  updateCampaign,
  showModal,
  setBanner,
  extractCSRF,
}) => {
  const [currentCampaign, setCurrentCampaign] = useState(selectedCampaign);

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const res = await fetchInstance(`/getCampaign/${selectedCampaign._id}`);
        if (!res.ok) throw new Error('Failed to fetch campaign');
        const data = await res.json();
        setCurrentCampaign(data);
      } catch (err) {
        console.error('Error fetching campaign:', err);
        setBanner(true, 'bad', 'Failed to load campaign data');
      }
    };

    if (selectedCampaign?._id) {
      fetchCampaignData();
    }
  }, [selectedCampaign._id]);

  const handlePreviewInputChange = (ev) => {
    const newCampaign = { ...currentCampaign };

    const changedProperty = ev.currentTarget.name;
    let newValue = ev.currentTarget.value;

    if (ev.currentTarget.type === 'checkbox') {
      newValue = ev.currentTarget.checked;
    }

    if (changedProperty === 'publicLink__expires') {
      if (!currentCampaign.publicLink) currentCampaign.publicLink = {};
      currentCampaign.publicLink.expires = newValue;
    } else {
      currentCampaign[changedProperty] = newValue;
    }

    newCampaign[changedProperty] = newValue;

    setCurrentCampaign(newCampaign);
  };

  const togglePublicLink = async () => {
    try {
      let campaign = { ...currentCampaign };

      if (
        campaign.publicLink &&
        campaign.publicLink.link &&
        !window.confirm(`Are you sure you want to destroy public link?`)
      )
        return;

      const endpoint = campaign.publicLink?.link
        ? '/removePublicLink'
        : '/togglePublicLink';
      const requestBody = campaign.publicLink?.link
        ? { cid: campaign._id }
        : {
            cid: campaign._id,
            expires: campaign.publicLink ? campaign.publicLink.expires : null,
          };

      const res = await fetchInstance(endpoint, {
        method: 'PUT',
        body: JSON.stringify(requestBody),
      });

      if (!res.ok) throw new Error('Failed to update public link');

      const data = await res.json();

      if (data.error) throw new Error(data.message);

      setCurrentCampaign(data.campaign);
      setBanner(
        true,
        'good',
        campaign.publicLink?.link
          ? 'Public link removed successfully'
          : 'Public link generated successfully',
      );
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  const saveCampaign = async (ev) => {
    try {
      ev.stopPropagation();
      ev.preventDefault();

      const { _id, ...campaignData } = currentCampaign;

      if (!_id) {
        throw new Error('Campaign ID is required');
      }

      if (campaignData.publicLink?.expires) {
        campaignData.publicLink.expires = new Date(
          campaignData.publicLink.expires,
        ).toISOString();
      }

      const requestBody = {
        campaign: {
          ...campaignData,
          client: campaignData.client?._id || campaignData.client,
          group: campaignData.group?._id || campaignData.group || null,
        },
      };

      const endpoint = '/updateCampaign/' + _id;
      const res = await fetchInstance(endpoint, {
        method: 'PUT',
        body: JSON.stringify(requestBody),
      });

      if (!res.ok) throw new Error('Failed to save campaign');

      const data = await res.json();

      if (data.error) throw new Error(data.message);

      handleCloseModal();
      updateCampaign(data.campaign);
      setBanner(true, 'good', 'Sharing updated successfully');
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  return (
    <GeneralModalStyles
      className="modal-wrapper"
      style={{ opacity: showModal ? 1 : 0 }}
    >
      <div className="modal-body">
        <button
          type="button"
          onClick={handleCloseModal}
          className="modal-close-button"
        >
          <XCircleFill color="black" width={34} height={34} />
        </button>

        <FormStyles onSubmit={saveCampaign}>
          <FlexContainer direction="column" gap="40px" alignItems="center">
            <Title>Sharing</Title>
            <div style={{ width: '100%' }}>
              <div className="group">
                <label>Public Link</label>
                <div style={{ display: 'flex' }}>
                  <input
                    readOnly
                    style={{ height: '100%' }}
                    value={
                      currentCampaign &&
                      currentCampaign.publicLink &&
                      currentCampaign.publicLink.link
                        ? `${
                            window.location.protocol +
                            '//' +
                            window.location.hostname +
                            (window.location.port
                              ? ':' + window.location.port
                              : '')
                          }/campaign/${currentCampaign.publicLink.link}/shared`
                        : 'Not public'
                    }
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${
                          window.location.protocol +
                          '//' +
                          window.location.hostname +
                          (window.location.port
                            ? ':' + window.location.port
                            : '')
                        }/campaign/${currentCampaign.publicLink.link}/shared`,
                      );
                      setBanner(true, 'good', 'Link copied to clipboard');
                    }}
                  />
                  <button
                    onClick={togglePublicLink}
                    type="button"
                    className={
                      currentCampaign &&
                      currentCampaign.publicLink &&
                      currentCampaign.publicLink.link
                        ? 'warning-button'
                        : 'confirm-button'
                    }
                    style={{ marginLeft: '10px', padding: '1rem' }}
                  >
                    {currentCampaign &&
                    currentCampaign.publicLink &&
                    currentCampaign.publicLink.link
                      ? 'Destroy'
                      : 'Generate'}
                  </button>
                </div>
              </div>

              <div className="group">
                <label>Expire Date</label>
                <input
                  name="publicLink__expires"
                  type="date"
                  value={
                    currentCampaign.publicLink &&
                    currentCampaign.publicLink.expires
                      ? new Date(currentCampaign.publicLink.expires)
                          .toISOString()
                          .substr(0, 10)
                      : ''
                  }
                  onChange={handlePreviewInputChange}
                />
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label>Status</label>
                {/* {this.props.activeCampaign.active ? "Active" : "Disabled"} */}
                <div style={{ marginLeft: '1rem' }}>
                  <input
                    id="singleToggle"
                    name="active"
                    type="checkbox"
                    checked={currentCampaign.active ? true : false}
                    onChange={handlePreviewInputChange}
                  />
                  <label htmlFor="singleToggle" />
                </div>
              </div>
            </div>

            <div className="button-wrapper">
              <button className="confirm-button">Save</button>
            </div>
          </FlexContainer>
        </FormStyles>
      </div>
    </GeneralModalStyles>
  );
};

export default ShareCampaign;

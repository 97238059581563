const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export default function formatDateWithPastWeek(date) {
  if (!date) {
    return '';
  }

  const now = new Date();
  const d = new Date(date);
  const diffTime = Math.abs(now - d);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays <= 7) {
    return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
  }

  const year = d.getFullYear();
  const month = months[d.getMonth()];
  const day = String(d.getDate()).padStart(2, '0');

  return `${day} ${month} ${year}`;
}

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './Select.module.css';

/**
 * @typedef {Object} SelectOption
 * @property {string|number} value - Option value
 * @property {string} label - Option display text
 */

/**
 * @typedef {Object} SelectProps
 * @property {string} name - Select name attribute and id
 * @property {string|number} [value] - Currently selected value
 * @property {(event: React.ChangeEvent<HTMLSelectElement>) => void} onChange - Change event handler
 * @property {SelectOption[]} options - Array of options to display
 * @property {string} [placeholder='Select an option'] - Placeholder text for empty selection
 * @property {string} [label] - Label text for the select
 * @property {boolean} [required=false] - Whether the select is required
 * @property {string} [className] - Additional CSS class names
 * @property {string} [error] - Error message to display
 * @property {boolean} [disabled=false] - Whether the select is disabled
 */

/**
 * Reusable Select component with label and error handling
 * @param {SelectProps} props
 * @returns {JSX.Element}
 */
const Select = ({
  name,
  value,
  onChange,
  options,
  placeholder,
  label,
  required = false,
  className = '',
  error,
  disabled = false,
}) => {
  /**
   * Combines root class with any additional classes
   * @type {string}
   */
  const rootClassName = cx(styles.root, className);

  /**
   * Combines select class with error class if present
   * @type {string}
   */
  const selectClassName = cx(styles.select, error && styles.error);

  return (
    <div className={rootClassName}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        className={selectClassName}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Select;

import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import {
  PencilSquare,
  ShareFill,
  Trash3,
  FolderFill,
  CalendarRange,
  Globe,
} from 'react-bootstrap-icons';

import DefaultLayout from '../../Components/DefaultLayout';
import FlexContainer from '../../Components/styles/FlexContainer';
import GridContainer from '../../Components/styles/GridContainer';
import Title from '../../Components/styles/TitleStyle';
import styles from './CampaignBannerPage.module.scss';
import fetchInstance from '../../utils/fetchInstance';
import { GeneralModalContext } from '../../Components/GeneralModal';
import CampaignDetails from '../../Components/Campaigns/CampaignDetails';
import ShareCampaign from '../../Components/Campaigns/ShareCampaign';
import DeleteModal from '../../Components/Modals/DeleteModal';
import BannerSetCard from './BannerSetCard';
import BannerSetSearchBar from './BannerSetSearchBar';
import Pagination from '../Pagination/Pagination';
import BannerSetModal from './BannerSetModal';
import clientBg from '../../images/clientBg.jpg';
import SocialIcon from '../SocialIcon/SocialIcon';
import formatDateWithPastWeek from '../../utils/formatDateWithPastWeek';
import { formatDateRange } from '../../utils/formatDateRange';
import { useDebounce } from '../../hooks/useDebounce';
import config from '../../config';

const CampaignBannerPage = ({ match, setBanner, extractCSRF, user }) => {
  const [campaign, setCampaign] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [bannerSets, setBannerSets] = useState([]);
  const [visibleBannerSets, setVisibleBannerSets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { ITEMS_PER_PAGE } = config;
  const [abortController] = useState(new AbortController());

  const [showBannerSetModal, setShowBannerSetModal] = useState(false);
  const [currentTitle, setCurrentTitle] = useState('');

  const { showModal, handleShowModal, handleCloseModal } =
    useContext(GeneralModalContext);

  const history = useHistory();

  const [campaignId, setCampaignId] = useState(match.params.id);

  const [selectedBannerSetId, setSelectedBannerSetId] = useState(null);
  const [selectedBannerSet, setSelectedBannerSet] = useState(null);

  const debouncedFetchBannerSets = useDebounce((searchTerm) => {
    fetchBannerSets(1, searchTerm);
  }, 500);

  const handleSearchBannerSetInput = (ev) => {
    const searchTerm = ev.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(1);
    debouncedFetchBannerSets(searchTerm);
  };

  const fetchBannerSets = async (page, name = searchTerm) => {
    try {
      setLoading(true);
      const queryParams = new URLSearchParams({
        page: page.toString(),
        limit: ITEMS_PER_PAGE.toString(),
        clientId: campaign?.client?._id,
        ...(name && name.trim() !== '' && { name }),
        ...(campaign?.group?._id && { groupId: campaign.group._id }),
      });

      const res = await fetchInstance(
        `/getBannerSets/${match.params.id}?${queryParams}`,
        {
          signal: abortController.signal,
        },
      );

      if (!res.ok) throw new Error(res.statusText);
      const data = await res.json();

      setBannerSets(data.bannerSets || []);
      setVisibleBannerSets(data.bannerSets || []);
      setTotalPages(Math.ceil(data.total / ITEMS_PER_PAGE));
    } catch (err) {
      if (err.name === 'AbortError') return;
      console.error('Banner sets error:', err);
      setBanner(true, 'bad', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchBannerSets(pageNumber);
  };

  const openEditModal = () => {
    setSelectedCampaign(campaign);
    setModalType('campaign-modal');
    handleShowModal();
  };

  const openShareModal = () => {
    setSelectedCampaign(campaign);
    setModalType('share-modal');
    handleShowModal();
  };

  const openDeleteModal = (bannerSet) => {
    setSelectedBannerSet(bannerSet);
    setModalType('delete-banner-set');
    handleShowModal();
  };

  const openBannerSetModal = (bannerSet) => {
    setCurrentTitle(bannerSet.name);
    setSelectedBannerSetId(bannerSet._id);
    setShowBannerSetModal(true);
    setModalType('edit-banner-set-title');
    handleShowModal();
  };

  const handleTitleUpdate = async (newTitle) => {
    try {
      const res = await fetchInstance(
        `/updateBannerSet/${selectedBannerSetId}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            bannerSet: {
              name: newTitle,
            },
          }),
        },
      );

      if (!res.ok) throw new Error('Failed to update banner set title');

      await fetchBannerSets(currentPage);
      setShowBannerSetModal(false);
      handleCloseModal();
      setBanner(true, 'good', 'Banner set title updated successfully');
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  const updateCampaign = async (updatedCampaign) => {
    try {
      const { _id, ...campaignData } = updatedCampaign;

      const client = campaignData.group?.client || campaignData.client;

      const res = await fetchInstance(`/updateCampaign/${_id}`, {
        method: 'PUT',
        body: JSON.stringify({
          campaign: {
            ...campaignData,
            client: client?._id,
            group: campaignData.group?._id || campaignData.group || null,
          },
        }),
      });

      if (!res.ok) throw new Error('Failed to save campaign');

      await fetchCampaign();

      setSelectedCampaign(null);
      handleCloseModal();
      setBanner(true, 'good', 'Campaign updated successfully');
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  const deleteCampaign = async () => {
    try {
      const campaignToDelete = { ...selectedCampaign };
      const res = await fetchInstance(
        `/deleteCampaign/${campaignToDelete._id}`,
        {
          method: 'DELETE',
          body: JSON.stringify({ campaign: campaignToDelete }),
        },
      );

      if (!res.ok) throw new Error(res.status + ' ' + res.statusText);

      await fetchBannerSets(currentPage);

      handleCloseModal();
      setBanner(true, 'good', 'Campaign deleted successfully');

      history.push(`/client/${campaignToDelete.client?._id}`);
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  const fetchCampaign = async () => {
    try {
      setLoading(true);
      const res = await fetchInstance(`/getCampaign/${campaignId}`);
      if (!res.ok) throw new Error(res.statusText);
      const data = await res.json();
      setCampaign(data);
      await fetchBannerSets(1);
    } catch (err) {
      setBanner(true, 'bad', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDuplicateBannerSet = async (bannerSet) => {
    try {
      const res = await fetchInstance(`/duplicateBannerSet/${bannerSet._id}`, {
        method: 'POST',
      });

      if (!res.ok) throw new Error('Failed to duplicate banner set');

      await fetchBannerSets(currentPage);
      setBanner(true, 'good', 'Banner set duplicated successfully');
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  const handleDeleteBannerSet = async (bannerSet) => {
    try {
      const res = await fetchInstance(`/deleteBannerSet/${bannerSet._id}`, {
        method: 'DELETE',
      });

      if (!res.ok) throw new Error('Failed to delete banner set');

      await fetchBannerSets(currentPage);
      handleCloseModal();
      setBanner(
        true,
        'good',
        `Banner set "${bannerSet.name}" deleted successfully`,
      );
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  useEffect(() => {
    if (campaignId) {
      fetchCampaign();
    }

    return () => {
      abortController.abort();
    };
  }, [campaignId]);

  return (
    <DefaultLayout>
      <FlexContainer direction="column" gap="30px">
        <FlexContainer direction="column" gap="20px">
          <Link
            to={`/client/${campaign?.client?._id}`}
            className={styles.backLink}
          >
            <IoArrowBack />
            Back
          </Link>

          <FlexContainer direction="column" gap="10px">
            <div className={styles.breadcrumb}>
              <Link
                to={`/client/${campaign?.client?._id}`}
                className={styles.clientLink}
              >
                {campaign?.client?.name}
              </Link>
              {' / '}
              {campaign?.group && (
                <FlexContainer alignItems="center" gap="5px">
                  <FolderFill color="#8d8d8d" width={17} height={17} />
                  <Link
                    to={`/groups/${campaign?.group?._id}`}
                    className={styles.groupLink}
                  >
                    {campaign?.group?.name}
                  </Link>
                  {' / '}
                </FlexContainer>
              )}
            </div>
            <h1 className={styles.campaignTitle}>{campaign?.name}</h1>
          </FlexContainer>

          <FlexContainer gap="10px">
            <button className={styles.actionButton} onClick={openEditModal}>
              <PencilSquare width={16} height={16} /> Edit
            </button>
            <button className={styles.actionButton} onClick={openShareModal}>
              <ShareFill width={16} height={16} /> Share
            </button>
            <button className={styles.actionButton} onClick={openDeleteModal}>
              <Trash3 width={16} height={16} /> Delete
            </button>
          </FlexContainer>

          <FlexContainer direction="column" gap="5px">
            <FlexContainer gap="30px" alignItems="center">
              {campaign?.platforms?.length > 0 && (
                <FlexContainer direction="row" alignItems="center" gap="5px">
                  <FlexContainer gap="5px">
                    <SocialIcon
                      networks={campaign?.platforms || ['']}
                      size={16}
                    />
                  </FlexContainer>
                  {campaign?.platforms?.length === 1 && (
                    <div className={styles.platform}>
                      {campaign?.platforms[0]}
                    </div>
                  )}
                </FlexContainer>
              )}
              <FlexContainer direction="row" alignItems="center" gap="5px">
                <CalendarRange width={16} height={16} />
                <div className={styles.dateRange}>
                  {formatDateRange(campaign?.startDate, campaign?.endDate)}
                </div>
              </FlexContainer>
              <FlexContainer direction="row" alignItems="center" gap="30px">
                <div className={styles.urlContainer}>
                  <Globe
                    width={16}
                    height={16}
                    style={{
                      minWidth: '16px',
                      width: '16px',
                      height: '16px',
                      flexShrink: 0,
                    }}
                  />
                  <a
                    href={campaign?.url}
                    className={styles.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {campaign?.url}
                  </a>
                </div>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer direction="row" alignItems="center" gap="10px">
              <div className={styles.status}>
                {campaign?.active ? (
                  <span className={styles.activeStatus}>Active</span>
                ) : (
                  <span className={styles.inactiveStatus}>Inactive</span>
                )}
              </div>
              <div>{formatDateWithPastWeek(campaign?.created)}</div>
            </FlexContainer>
            {campaign?.notes && (
              <div className={styles.notes}>{campaign?.notes}</div>
            )}
          </FlexContainer>
        </FlexContainer>

        <FlexContainer direction="column" gap="20px">
          <FlexContainer justifyContent="space-between" alignItems="center">
            <Title className={styles.bannerSetsTitle}>Banner sets</Title>
            <BannerSetSearchBar
              value={searchTerm}
              handleSearchBannerSetInput={handleSearchBannerSetInput}
            />
          </FlexContainer>

          <GridContainer columns="repeat(4, 1fr)" gap="20px" alignItems="start">
            {loading ? (
              <div>Loading...</div>
            ) : visibleBannerSets.length > 0 ? (
              visibleBannerSets.map((bannerSet) => (
                <BannerSetCard
                  key={bannerSet?._id}
                  bannerSet={bannerSet}
                  openBannerSetModal={openBannerSetModal}
                  handleDuplicate={handleDuplicateBannerSet}
                  openDeleteModal={openDeleteModal}
                />
              ))
            ) : (
              <div>No banner sets found</div>
            )}
          </GridContainer>

          {!loading && totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </FlexContainer>
      </FlexContainer>

      {showModal && modalType === 'campaign-modal' && (
        <CampaignDetails
          extractCSRF={extractCSRF}
          selectedCampaign={selectedCampaign}
          handleCloseModal={handleCloseModal}
          updateCampaign={updateCampaign}
          setBanner={setBanner}
          showModal={showModal}
          clientId={campaign?.client?._id}
          userId={user?._id}
        />
      )}

      {showModal && modalType === 'share-modal' && (
        <ShareCampaign
          extractCSRF={extractCSRF}
          selectedCampaign={selectedCampaign}
          handleCloseModal={handleCloseModal}
          showModal={showModal}
          setBanner={setBanner}
          updateCampaign={updateCampaign}
        />
      )}

      {showModal && modalType === 'delete-campaign' && (
        <DeleteModal
          acceptFunction={deleteCampaign}
          showModal={showModal}
          message={[
            'Do you really want to delete ',
            <b>{selectedCampaign?.name}</b>,
            '?',
          ]}
          handleCloseModal={handleCloseModal}
        />
      )}

      {showModal && modalType === 'edit-banner-set-title' && (
        <BannerSetModal
          show={showBannerSetModal}
          handleClose={() => setShowBannerSetModal(false)}
          currentTitle={currentTitle}
          onSave={handleTitleUpdate}
        />
      )}

      {showModal && modalType === 'delete-banner-set' && (
        <DeleteModal
          acceptFunction={() => handleDeleteBannerSet(selectedBannerSet)}
          showModal={showModal}
          message={[
            'Do you really want to delete banner set "',
            <b key="name">{selectedBannerSet?.name}</b>,
            '"?',
          ]}
          handleCloseModal={handleCloseModal}
        />
      )}
    </DefaultLayout>
  );
};

export default CampaignBannerPage;

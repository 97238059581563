import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ArrowRightShort } from 'react-bootstrap-icons';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import styles from './InitSetupForm.module.css';
import DefaultLayout from '../../DefaultLayout';
import TopBar from '../TopBar';
import Input from '../../FormComponents/Input';
import Select from '../../FormComponents/Select';
import Button from '../../FormComponents/Button';
import NetworkButtons from '../../FormComponents/NetworkButtons';
import fetchInstance from '../../../utils/fetchInstance';
import NetworkEnum from '../../../shared/enums/networkEnum';
import ResolutionButtons from '../../FormComponents/ResolutionButtons';
import CustomResolutionInput from '../../FormComponents/CustomResolutionInput';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .min(3, 'Title must be at least 3 characters'),
  clientId: Yup.string().required('Client is required'),
  campaignId: Yup.string().required('Campaign is required'),
  resolutions: Yup.array()
    .min(1, 'At least one resolution must be selected')
    .required('At least one resolution is required'),
});

const CreateBannerSetForm = () => {
  const history = useHistory();
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [customResolution, setCustomResolution] = useState({
    width: '',
    height: '',
  });
  const [error, setError] = useState(null);
  const [isPreselected, setIsPreselected] = useState(false);

  const networkResolutions = {
    [NetworkEnum.FACEBOOK]: ['1200x1200', '1200x628', '1080x1080'],
    [NetworkEnum.INSTAGRAM]: ['1080x1080', '1080x1350', '1080x1920'],
    [NetworkEnum.GOOGLE]: ['300x250', '336x280', '728x90'],
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await fetchInstance('/getClients');
      if (response.ok) {
        const data = await response.json();
        setClients(data.clients);
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const fetchCampaigns = async (clientId) => {
    try {
      setError(null);

      if (!clientId || clientId.trim() === '') {
        setCampaigns([]);
        return;
      }

      const response = await fetchInstance(
        `/getCampaigns?page=1&limit=99999&clientId=${clientId}`,
      );

      const data = await response.json();

      if (!response.ok || data.error) {
        throw new Error(data.message || 'Failed to fetch campaigns');
      }

      setCampaigns(data.campaigns);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setError(error.message);
      setCampaigns([]);
    }
  };

  const handleCustomResolutionChange = (e) => {
    const { name, value } = e.target;
    setCustomResolution({ ...customResolution, [name]: value });
  };

  const searchParams = new URLSearchParams(location.search);
  const preselectedCampaignId = searchParams.get('cid');

  const fetchCampaignDetails = async (formikBag) => {
    if (!preselectedCampaignId) return;

    try {
      const response = await fetchInstance(
        `/getCampaign/${preselectedCampaignId}`,
      );
      const campaign = await response.json();

      if (response.ok && campaign) {
        await fetchCampaigns(campaign.client._id);

        formikBag.setFieldValue('clientId', campaign.client._id);
        formikBag.setFieldValue('campaignId', campaign._id);

        setIsPreselected(true);
      } else {
        console.error('Invalid campaign ID');
        setError('Campaign not found');
      }
    } catch (error) {
      console.error('Error fetching campaign details:', error);
      setError('Failed to load campaign details');
    }
  };

  const initialValues = {
    title: '',
    clientId: '',
    campaignId: '',
    network: NetworkEnum.FACEBOOK,
    resolutions: [],
  };

  const transformResolutions = (resolutionsArray) => {
    return resolutionsArray.map((resolution) => {
      const [width, height] = resolution.split('x').map(Number);
      return {
        width,
        height,
      };
    });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const transformedData = {
        bannerSet: {
          campaign: values.campaignId,
          name: values.title,
          isDraft: true,
          platform: values.network,
          resolutions: transformResolutions(values.resolutions),
        },
      };

      const response = await fetchInstance('/bannerSet', {
        method: 'POST',
        body: JSON.stringify(transformedData),
      });

      if (response.ok) {
        const { bannerSet } = await response.json();
        history.push(`/banner-creator/${bannerSet._id}`);
      } else {
        throw new Error('Failed to create banner set');
      }
    } catch (error) {
      console.error('Error creating project:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DefaultLayout>
      <div className={styles.root}>
        <TopBar title="Create Banner Set" />
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(formikProps) => {
              useEffect(() => {
                if (preselectedCampaignId) {
                  fetchCampaignDetails(formikProps);
                }
              }, [preselectedCampaignId]);

              const {
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              } = formikProps;

              return (
                <Form className={styles.form}>
                  {error && <div className={styles.errorMessage}>{error}</div>}

                  <div className={styles.formContainer}>
                    <h2 className={styles.formHeader}>Initial setup</h2>

                    <Input
                      name="title"
                      value={values.title}
                      onChange={(e) => setFieldValue('title', e.target.value)}
                      label="Title"
                      className={styles.formInput}
                      error={touched.title && errors.title}
                    />

                    <div className={styles.formDoubleRow}>
                      <Select
                        name="clientId"
                        value={values.clientId}
                        onChange={(e) => {
                          const clientId = e.target.value;
                          setFieldValue('clientId', clientId);
                          setFieldValue('campaignId', '');
                          if (clientId) {
                            setFieldTouched('clientId', false);
                          }
                          if (clientId) fetchCampaigns(clientId);
                        }}
                        options={clients.map((client) => ({
                          value: client._id,
                          label: client.name,
                        }))}
                        placeholder="Select Client"
                        label="Client"
                        error={touched.clientId && errors.clientId}
                        disabled={isPreselected}
                      />

                      <Select
                        name="campaignId"
                        value={values.campaignId}
                        onChange={(e) => {
                          setFieldValue('campaignId', e.target.value);
                          if (e.target.value) {
                            setFieldTouched('campaignId', false);
                          }
                        }}
                        options={campaigns.map((campaign) => ({
                          value: campaign._id,
                          label: campaign.name,
                        }))}
                        placeholder="Select Campaign"
                        label="Campaign"
                        disabled={!values.clientId || isPreselected}
                        error={touched.campaignId && errors.campaignId}
                      />
                    </div>

                    <NetworkButtons
                      selectedNetwork={values.network}
                      onChange={(network) => {
                        setFieldValue('network', network);
                        setFieldValue('resolutions', []);
                      }}
                    />
                    <div className={styles.resolutionsContainer}>
                      <ResolutionButtons
                        networkResolutions={networkResolutions[values.network]}
                        selectedResolutions={values.resolutions}
                        onResolutionToggle={(resolution) => {
                          const newResolutions = values.resolutions.includes(
                            resolution,
                          )
                            ? values.resolutions.filter((r) => r !== resolution)
                            : [...values.resolutions, resolution];
                          setFieldValue('resolutions', newResolutions);
                        }}
                        error={touched.resolutions && errors.resolutions}
                      />
                      <CustomResolutionInput
                        customResolution={customResolution}
                        onCustomResolutionChange={handleCustomResolutionChange}
                        onAddCustomResolution={() => {
                          const newResolution = `${customResolution.width}x${customResolution.height}`;
                          if (!values.resolutions.includes(newResolution)) {
                            setFieldValue('resolutions', [
                              ...values.resolutions,
                              newResolution,
                            ]);
                            setCustomResolution({ width: '', height: '' });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <Button
                    type="submit"
                    variant="primary"
                    className={styles.submitButton}
                    rightIcon={<ArrowRightShort />}
                    disabled={isSubmitting}
                  >
                    Continue
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default CreateBannerSetForm;

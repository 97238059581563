import React from 'react';
import Moveable from 'react-moveable';
import { ref } from 'framework-utils';
import ParserStyle from 'style-to-object';

import '../../styles.scss';

import BannerElement from '../BannerElement';

class BannerImageItem extends BannerElement {
  // constructor(props){
  //     super(props)
  // }

  render() {
    const { target } = this.state;
    const { id, options, locked, container, offsetH, offsetW } = this.props;

    // console.log('BannerImageItem', this.props);

    const { props } = this;

    const { onRenderStart, onDrag, onResize, onRotate, onScale, onEnd } = this;

    const elDefaultStyle = { width: '100%', height: '100%', objectFit: 'fill' };
    const elDefaultClass = `${props.id}`;

    const elStyle = { ...elDefaultStyle, ...ParserStyle(options.style) };
    const elClass = `${elDefaultClass} ${options.class}`;

    return (
      <BannerElement {...props}>
        <Moveable
          ref={ref(this, 'moveable')}
          key={id}
          target={target}
          container={container}
          snappable={true}
          snapCenter={true}
          bounds={{ left: 0, top: 0, bottom: offsetH, right: offsetW }}
          draggable={!locked}
          scalable={!locked}
          resizable={!locked}
          rotatable={!locked}
          origin={false}
          throttleDrag={1}
          throttleRotate={0.2}
          throttleResize={1}
          throttleScale={0.01}
          onRenderStart={onRenderStart}
          onDrag={onDrag}
          onResize={onResize}
          onScale={onScale}
          onRotate={onRotate}
          onDragEnd={onEnd}
          onScaleEnd={onEnd}
          onResizeEnd={onEnd}
          onRotateEnd={onEnd}
        />

        <div
          id={id}
          onClick={this.onClick}
          className="moveable banner-item image-item"
        >
          <img
            id={`${id}_el`}
            className={elClass}
            style={elStyle}
            src={options.src}
            alt=""
          />
        </div>

        <div className="label" ref={ref(this, 'label')} />
      </BannerElement>
    );
  }
}

export default BannerImageItem;

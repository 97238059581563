export const formatDateRange = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const formatDay = (day) => day.toString().padStart(2, '0');

  if (
    start.getFullYear() === end.getFullYear() &&
    start.getMonth() === end.getMonth()
  ) {
    return `${formatDay(start.getDate())} - ${formatDay(end.getDate())} ${
      months[start.getMonth()]
    } ${end.getFullYear()}`;
  }

  if (start.getFullYear() === end.getFullYear()) {
    return `${formatDay(start.getDate())} ${
      months[start.getMonth()]
    } - ${formatDay(end.getDate())} ${
      months[end.getMonth()]
    } ${end.getFullYear()}`;
  }

  return `${formatDay(start.getDate())} ${
    months[start.getMonth()]
  } ${start.getFullYear()} - ${formatDay(end.getDate())} ${
    months[end.getMonth()]
  } ${end.getFullYear()}`;
};

import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Floppy } from 'react-bootstrap-icons';

import styles from './Creator.module.css';
import BannerCreatorPreview from './BannerCreatorPreview';
import CreatorActions from './Actions';
import DefaultLayout from '../../DefaultLayout';
import TopBar from '../TopBar';
import ResolutionButtons from '../../FormComponents/ResolutionButtons';
import fetchInstance from '../../../utils/fetchInstance';
import NavigationButtons from './Elements/NavigationButtons';
import DebugInfo from './Elements/DebugInfo';
import { Dispatcher, Events } from '../../Events';
import Button from '../../FormComponents/Button';
import { ButtonVariantEnum } from '../../../shared/enums/buttonEnum';
import getDefaultFrame from '../Helpers/GetDefaultFrame';
import normalizeVariations from '../Helpers/NormalizeVariations';

// Funcție helper pentru a obține cel mai mare z-index
const getMaxZIndex = (elements) => {
  let maxZ = 0;
  elements.forEach((element) => {
    Object.values(element.variations).forEach((variation) => {
      if (variation.frame && variation.frame['z-index'] > maxZ) {
        maxZ = variation.frame['z-index'];
      }
    });
  });
  return maxZ;
};

const BannerCreator = () => {
  const { id } = useParams();
  const [bannerSet, setBannerSet] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedResolution, setSelectedResolution] = useState(null);
  const [elements, setElements] = useState([]);
  const [backgrounds, setBackgrounds] = useState({ variations: {} });
  const [key, setKey] = useState(0);
  const isMounted = useRef(true);
  const allResolutionsRef = useRef([]);
  const [bannerId, setBannerId] = useState(null);

  const history = useHistory();

  const allResolutions = useMemo(() => {
    return bannerSet?.resolutions?.map((res) => `${res.width}x${res.height}`);
  }, [bannerSet]);

  useEffect(() => {
    allResolutionsRef.current = allResolutions || [];
  }, [allResolutions]);

  useEffect(() => {
    const fetchBannerSet = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const response = await fetchInstance(
          `/getBannerSet/${id}?aggregate=true`,
        );
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch banner set');
        }

        setBannerSet(data);

        if (data.banners && data.banners.length > 0) {
          const existingBanner = data.banners[0];
          setBannerId(existingBanner.id || existingBanner._id);

          if (existingBanner.elements) {
            const normalizedElements = existingBanner.elements.map(
              (element, index) => ({
                ...element,
                id: element.id || element._id,
                variations: normalizeVariations(
                  element.variations,
                  data.resolutions.map((res) => `${res.width}x${res.height}`),
                  element.type,
                  index,
                ),
              }),
            );
            setElements(normalizedElements);
          }

          if (existingBanner.backgrounds) {
            setBackgrounds({
              variations: existingBanner.backgrounds,
            });
          }
        }

        if (data.resolutions && data.resolutions.length > 0) {
          const firstRes = data.resolutions[0];
          setSelectedResolution(`${firstRes.width}x${firstRes.height}`);
        }
      } catch (error) {
        console.error('Error fetching banner set:', error);
        setError('Failed to load banner set. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchBannerSet();
    }
  }, [id]);

  // Mutăm selectedResolution în ref pentru a evita re-renderurile
  const selectedResolutionRef = useRef(null);

  // Handler pentru schimbarea rezoluției
  const handleResolutionToggle = (resolution) => {
    selectedResolutionRef.current = resolution;
    setSelectedResolution(resolution);
    setKey((prev) => prev + 1);
  };

  const handlePrevResolution = () => {
    const currentIndex = allResolutions.indexOf(selectedResolution);
    if (currentIndex > 0) {
      const prevResolution = allResolutions[currentIndex - 1];
      handleResolutionToggle(prevResolution);
    }
  };

  const handleNextResolution = () => {
    const currentIndex = allResolutions.indexOf(selectedResolution);
    if (currentIndex < allResolutions.length - 1) {
      const nextResolution = allResolutions[currentIndex + 1];
      handleResolutionToggle(nextResolution);
    }
  };

  const handleSubmit = async () => {
    handleSave();
    history.push(`/campaign/${bannerSet.campaign}`);
  };

  const handleSave = async () => {
    toast.promise(saveBanner(), {
      loading: 'Saving...',
      success: <b>Banner saved!</b>,
      error: <b>Could not save.</b>,
    });
  };

  const saveBanner = async () => {
    const submissionData = {
      elements: elements.map((element) => {
        const isFrontendId = element.id.startsWith('banner_element_');

        return {
          ...(bannerId && !isFrontendId ? { _id: element.id } : {}),
          type: element.type,
          variations: element.variations,
        };
      }),
      backgrounds: backgrounds.variations,
    };

    try {
      let response;
      if (bannerId) {
        console.log('Updating existing banner:', bannerId);
        response = await fetchInstance(`/updateBanner/${bannerId}`, {
          method: 'PUT',
          body: JSON.stringify({
            banner: submissionData,
          }),
        });

        const updatedData = await response.json();
        if (response.ok && updatedData.banner.elements) {
          const updatedElements = updatedData.banner.elements.map(
            (element, index) => ({
              ...element,
              id: element._id || element.id,
              variations: normalizeVariations(
                element.variations,
                allResolutionsRef.current,
                element.type,
                index,
              ),
            }),
          );
          setElements(updatedElements);
        }
      } else {
        response = await fetchInstance('/banner', {
          method: 'POST',
          body: JSON.stringify({
            ...submissionData,
            bannerSet: id,
          }),
        });

        const data = await response.json();
        if (response.ok) {
          setBannerId(data.id || data._id);

          if (data.elements) {
            const updatedElements = data.elements.map((element, index) => ({
              ...element,
              id: element._id || element.id,
              variations: normalizeVariations(
                element.variations,
                allResolutionsRef.current,
                element.type,
                index,
              ),
            }));
            setElements(updatedElements);
          }
        }
      }

      if (!response.ok) {
        throw new Error('Failed to save banner');
      }
    } catch (error) {
      console.error('Error saving banner:', error);
      throw error;
    }
  };

  const elementsRef = useRef([]); // Adăugăm un ref pentru a urmări elements

  // Handler pentru adăugarea unui element nou
  const handleAddElement = useCallback((payload) => {
    if (!isMounted.current) return;

    console.log('Add element clicked - payload:', payload);

    const elementId = `banner_element_${elementsRef.current.length}`;
    const variations = { ...payload.variations };

    // Filtrăm doar rezoluțiile enabled
    allResolutionsRef.current.forEach((resolution) => {
      if (variations[resolution]?.status === 'disabled') {
        // Pentru rezoluțiile disabled, păstrăm statusul și frame-ul gol
        variations[resolution] = {
          status: 'disabled',
          options: {},
          frame: getDefaultFrame({ zIndex: elementsRef.current.length }),
          locked: false,
        };
      } else if (variations[resolution]?.status === 'enabled') {
        // Pentru rezoluțiile enabled, păstrăm datele și adăugăm frame-ul dacă lipsește
        variations[resolution] = {
          ...variations[resolution],
          frame:
            variations[resolution].frame ??
            getDefaultFrame({ zIndex: elementsRef.current.length }),
          locked: false,
        };
      }
      // Nu mai tratăm cazul când rezoluția lipsește, deoarece ar trebui să avem toate rezoluțiile
    });

    console.log('Final variations:', variations);

    const newElement = {
      id: elementId,
      type: payload.type,
      variations: variations,
    };

    setElements((prev) => {
      const updatedElements = [...prev, newElement];
      elementsRef.current = updatedElements;
      return updatedElements;
    });
  }, []);

  const handleBringToFront = useCallback(
    (elementId) => {
      if (!isMounted.current) return;

      const maxZ = getMaxZIndex(elements);

      setElements((prevElements) =>
        prevElements.map((element) => {
          if (element.id === elementId) {
            // Actualizăm z-index-ul pentru toate rezoluțiile ale elementului selectat
            return {
              ...element,
              variations: Object.keys(element.variations).reduce(
                (acc, resolution) => ({
                  ...acc,
                  [resolution]: {
                    ...element.variations[resolution],
                    frame: {
                      ...element.variations[resolution].frame,
                      'z-index': maxZ + 1,
                    },
                  },
                }),
                {},
              ),
            };
          }
          return element;
        }),
      );
    },
    [elements],
  );

  const handleSendToBack = useCallback((elementId) => {
    if (!isMounted.current) return;

    setElements((prevElements) =>
      prevElements.map((element) => {
        if (element.id === elementId) {
          // Setăm z-index-ul la 0 pentru toate rezoluțiile ale elementului selectat
          return {
            ...element,
            variations: Object.keys(element.variations).reduce(
              (acc, resolution) => ({
                ...acc,
                [resolution]: {
                  ...element.variations[resolution],
                  frame: {
                    ...element.variations[resolution].frame,
                    'z-index': 0,
                  },
                },
              }),
              {},
            ),
          };
        }
        // Incrementăm z-index-ul pentru toate celelalte elemente
        return {
          ...element,
          variations: Object.keys(element.variations).reduce(
            (acc, resolution) => ({
              ...acc,
              [resolution]: {
                ...element.variations[resolution],
                frame: {
                  ...element.variations[resolution].frame,
                  'z-index':
                    (element.variations[resolution].frame['z-index'] || 0) + 1,
                },
              },
            }),
            {},
          ),
        };
      }),
    );
  }, []);

  // Actualizăm ref-ul când se schimbă elements
  useEffect(() => {
    console.log('Elements changed:', elements);
    elementsRef.current = elements;
  }, [elements]);

  // Handler pentru actualizarea unui element
  const handleUpdateElement = useCallback((payload) => {
    if (!isMounted.current) return;
    const { id, options, frame, variations: newVariations } = payload;

    setElements((prevElements) =>
      prevElements.map((element) => {
        if (element.id === id) {
          if (!element.variations[selectedResolutionRef.current]) {
            const firstResolution = Object.keys(element.variations)[0];
            element.variations[selectedResolutionRef.current] = {
              ...element.variations[firstResolution],
            };
          }

          return {
            ...element,
            variations: newVariations || {
              ...element.variations,
              [selectedResolutionRef.current]: {
                ...element.variations[selectedResolutionRef.current],
                options: options
                  ? {
                      ...element.variations[selectedResolutionRef.current]
                        .options,
                      ...options,
                    }
                  : element.variations[selectedResolutionRef.current].options,
                frame: frame
                  ? {
                      ...element.variations[selectedResolutionRef.current]
                        .frame,
                      ...frame,
                    }
                  : element.variations[selectedResolutionRef.current].frame,
              },
            },
          };
        }
        return element;
      }),
    );
  }, []);

  // Handler pentru actualizarea background-ului
  const handleBackgroundUpdate = useCallback((payload) => {
    if (!isMounted.current) return;

    const { resolution, file } = payload;
    setBackgrounds((prev) => ({
      ...prev,
      variations: {
        ...prev.variations,
        [resolution]: {
          src: file.path,
        },
      },
    }));
  }, []);

  const getCurrentElements = () => {
    return elements
      .map((element) => {
        const variation =
          element.variations[selectedResolution] ||
          element.variations[Object.keys(element.variations)[0]];

        return {
          id: element.id,
          type: element.type,
          options: variation.options,
          frame: variation.frame,
          locked: variation.locked,
          variations: element.variations,
        };
      })
      .sort((a, b) => (a.frame['z-index'] || 0) - (b.frame['z-index'] || 0));
  };

  // Adăugăm handlerul pentru ștergerea elementelor
  const handleDeleteElement = useCallback((payload) => {
    if (!isMounted.current) return;

    const { id } = payload;
    console.log('Deleting element:', id);

    setElements((prevElements) =>
      prevElements.filter((element) => element.id !== id),
    );
  }, []);

  // Event listeners setup
  useEffect(() => {
    if (!bannerSet) return;

    console.log('Adding event listeners');
    // Adăugăm event listeners
    Dispatcher.listen(Events.ADD_BANNER_ELEMENT, handleAddElement);
    Dispatcher.listen(Events.UPDATE_BANNER_ELEMENT, handleUpdateElement);
    Dispatcher.listen(Events.CHANGE_BANNER_BACKGROUND, handleBackgroundUpdate);
    Dispatcher.listen(Events.REMOVE_BANNER_ELEMENT, handleDeleteElement); // Adăugăm noul listener

    // return () => {
    //   // Cleanup
    //   Dispatcher.unlisten(Events.ADD_BANNER_ELEMENT, handleAddElement);
    //   Dispatcher.unlisten(Events.UPDATE_BANNER_ELEMENT, handleUpdateElement);
    //   Dispatcher.unlisten(
    //     Events.CHANGE_BANNER_BACKGROUND,
    //     handleBackgroundUpdate,
    //   );
    // };
  }, [bannerSet]); // Simplificăm dependențele

  // Actualizăm ref-ul când se schimbă selectedResolution
  useEffect(() => {
    selectedResolutionRef.current = selectedResolution;
    console.log('Selected resolution changed:', selectedResolution);
  }, [selectedResolution]);

  useEffect(() => {
    console.log('Test use effect');
  }, []);

  if (isLoading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;

  return (
    <DefaultLayout>
      <div className={styles.root}>
        <TopBar
          title="Banner elements"
          network={bannerSet?.platform}
          actions={
            <Button
              onClick={handleSave}
              leftIcon={<Floppy />}
              variant={ButtonVariantEnum.TEXT}
            >
              Save draft
            </Button>
          }
        />
        <div className={styles.contentWrapper}>
          <div className={styles.buttonsBar}>
            <div className={styles.actions}>
              <CreatorActions
                bannerSet={bannerSet}
                currentBackgrounds={backgrounds.variations}
              />
            </div>
            <div className={styles.resolutionButtons}>
              <ResolutionButtons
                networkResolutions={allResolutions}
                selectedResolutions={
                  selectedResolution ? [selectedResolution] : []
                }
                onResolutionToggle={handleResolutionToggle}
                hideLabel
                darkerButtons
              />
            </div>
          </div>
          <BannerCreatorPreview
            elements={getCurrentElements()}
            background={backgrounds.variations[selectedResolution]}
            resolution={selectedResolution}
            key={key}
            onBringToFront={handleBringToFront}
            onSendToBack={handleSendToBack}
          />
          <NavigationButtons
            selectedResolution={selectedResolution}
            availableResolutions={allResolutions}
            onPrevious={handlePrevResolution}
            onNext={handleNextResolution}
            onSubmit={handleSubmit}
          />
          <DebugInfo identifier={bannerSet._id} />
        </div>
        <div id="modal-holder"></div>
      </div>
    </DefaultLayout>
  );
};

const LoadingState = () => (
  <DefaultLayout>
    <div className={styles.root}>
      <TopBar title="Loading..." />
      <div className={styles.loading}>Loading banner set...</div>
    </div>
  </DefaultLayout>
);

const ErrorState = ({ error }) => (
  <DefaultLayout>
    <div className={styles.root}>
      <TopBar title="Error" />
      <div className={styles.error}>{error}</div>
    </div>
  </DefaultLayout>
);

export default BannerCreator;

import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { X } from 'react-bootstrap-icons';
import cx from 'classnames';
import styles from './Modal.module.css';

/**
 * @typedef {Object} ModalProps
 * @property {boolean} isOpen - Whether the modal is open
 * @property {() => void} onClose - Function to call when modal should close
 * @property {string} [title] - Optional title for the modal
 * @property {React.ReactNode} children - Content to render inside the modal
 * @property {number} [width=800] - Width of the modal in pixels
 * @property {boolean} [closeOnBackdrop=true] - Whether clicking the backdrop should close the modal
 * @property {boolean} [showCloseButton=true] - Whether to show the close button
 * @property {React.ReactNode} [footer] - Optional footer content
 */

/**
 * Modal component with fade animations
 * @param {ModalProps} props
 * @returns {React.ReactPortal | null}
 */
const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  width = 800,
  closeOnBackdrop = true,
  showCloseButton = true,
  footer,
}) => {
  /** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
  const [isAnimating, setIsAnimating] = useState(false);

  /** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
  const [shouldRender, setShouldRender] = useState(false);

  /** @type {[HTMLDivElement, React.Dispatch<React.SetStateAction<HTMLDivElement>>]} */
  const [portalElement] = useState(() => {
    const element = document.createElement('div');
    element.setAttribute('data-modal-container', '');
    return element;
  });

  useEffect(() => {
    document.body.appendChild(portalElement);
    return () => {
      document.body.removeChild(portalElement);
    };
  }, [portalElement]);

  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
      document.body.style.overflow = 'hidden';
    } else {
      setIsAnimating(false);
      const timer = setTimeout(() => {
        setShouldRender(false);
        document.body.style.overflow = 'unset';
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    if (shouldRender) {
      requestAnimationFrame(() => setIsAnimating(true));
    }
  }, [shouldRender]);

  /**
   * Handle clicks on the backdrop
   * @param {React.MouseEvent<HTMLDivElement>} e
   */
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget && closeOnBackdrop) {
      onClose();
    }
  };

  if (!shouldRender) return null;

  return createPortal(
    <div
      className={cx(styles.backdrop, {
        [styles.backdropVisible]: isAnimating,
      })}
      onClick={handleBackdropClick}
      role="presentation"
    >
      <div
        className={cx(styles.modal, {
          [styles.modalVisible]: isAnimating,
        })}
        style={{ width }}
      >
        {showCloseButton && (
          <button
            type="button"
            onClick={onClose}
            className={styles.closeButton}
            aria-label="Close modal"
          >
            <X />
          </button>
        )}
        {title && <h2 className={styles.title}>{title}</h2>}
        <div className={styles.content}>{children}</div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </div>,
    portalElement,
  );
};

export default Modal;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Ban, ExclamationTriangle } from 'react-bootstrap-icons';
import styles from './ResolutionButtons.module.css';

/**
 * @typedef {Object} AspectRatio
 * @property {number} width - Width in pixels
 * @property {number} height - Height in pixels
 */

/**
 * @typedef {Object} ResolutionButtonsProps
 * @property {string[]} networkResolutions - Array of available network resolutions
 * @property {string[]} selectedResolutions - Array of currently selected resolutions
 * @property {string[]} [disabledResolutions=[]] - Array of disabled resolutions
 * @property {string[]} [errorResolutions=[]] - Array of resolutions with errors
 * @property {(resolution: string) => void} onResolutionToggle - Callback when resolution is toggled
 * @property {boolean} [darkerButtons] - Whether to use darker button styling
 * @property {boolean} [hideLabel] - Whether to hide the "Resolutions" label
 * @property {string} [error] - Error message to display
 */

/**
 * Calculates the aspect ratio for a resolution string
 * @param {string} resolution - Resolution in format "widthxheight"
 * @returns {AspectRatio} Calculated aspect ratio dimensions
 */
const calculateAspectRatio = (resolution) => {
  const [width, height] = resolution.split('x').map(Number);
  const ratio = height / width;

  if (ratio > 1) {
    return {
      width: Math.round(22 / ratio),
      height: 22,
    };
  } else {
    return {
      width: 22,
      height: Math.round(22 * ratio),
    };
  }
};

/**
 * Resolution buttons component for selecting banner resolutions
 * @param {ResolutionButtonsProps} props
 * @returns {JSX.Element}
 */
const ResolutionButtons = ({
  networkResolutions,
  selectedResolutions,
  disabledResolutions = [],
  errorResolutions = [],
  onResolutionToggle,
  darkerButtons = false,
  hideLabel = false,
  error,
}) => {
  /** @type {string[]} */
  const allResolutions = [
    ...networkResolutions,
    ...selectedResolutions.filter(
      (resolution) => !networkResolutions.includes(resolution),
    ),
  ];

  return (
    <div className={styles.root}>
      {!hideLabel && <label className={styles.label}>Resolutions</label>}
      <div className={styles.resolutionButtons}>
        {allResolutions.map((resolution) => {
          const aspectRatio = calculateAspectRatio(resolution);
          const isDisabled = disabledResolutions.includes(resolution);
          const isError = errorResolutions.includes(resolution);

          return (
            <button
              key={resolution}
              type="button"
              className={cx(styles.resolutionButton, {
                [styles.active]: selectedResolutions.includes(resolution),
                [styles.darker]: darkerButtons,
                [styles.disabled]: isDisabled,
                [styles.error]: isError,
              })}
              onClick={() => onResolutionToggle(resolution)}
            >
              <div className={styles.aspectRatioWrapper}>
                <div
                  className={styles.aspectRatioIcon}
                  style={{
                    width: `${aspectRatio.width}px`,
                    height: `${aspectRatio.height}px`,
                  }}
                />
              </div>
              <div className={styles.overIcons}>
                {isDisabled && <Ban className={styles.disabledIcon} />}
                {isError && (
                  <ExclamationTriangle className={styles.errorIcon} />
                )}
              </div>
              {resolution}
            </button>
          );
        })}
      </div>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};

ResolutionButtons.propTypes = {
  networkResolutions: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedResolutions: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledResolutions: PropTypes.arrayOf(PropTypes.string),
  onResolutionToggle: PropTypes.func.isRequired,
  error: PropTypes.string,
  hideLabel: PropTypes.bool,
  darkerButtons: PropTypes.bool,
};

export default ResolutionButtons;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './TopBar.module.css';
import NetworkEnum from '../../shared/enums/networkEnum';
import { ReactComponent as FacebookIcon } from '../../images/logos/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../images/logos/instagram.svg';
import { ReactComponent as GoogleAdsIcon } from '../../images/logos/googleAds.svg';

const networkIcons = {
  [NetworkEnum.FACEBOOK]: FacebookIcon,
  [NetworkEnum.INSTAGRAM]: InstagramIcon,
  [NetworkEnum.GOOGLE]: GoogleAdsIcon,
};

const TopBar = ({ title, actions, network }) => {
  const renderNetworkIndicator = () => {
    if (!network) return null;

    const NetworkIcon = networkIcons[network];
    return (
      <div className={styles.networkIndicator}>
        <NetworkIcon width={22} height={22} className={styles.networkIcon} />
        <span className={styles.networkName}>{network}</span>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.topBarLeft}>
        <div className={styles.pageTitle}>{title}</div>
        {renderNetworkIndicator()}
      </div>
      <div className={styles.topBarRight}>{actions}</div>
    </div>
  );
};

TopBar.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.node,
  network: PropTypes.oneOf(Object.values(NetworkEnum)),
};

TopBar.defaultProps = {
  title: 'Banner Creator',
  actions: null,
  network: null,
};

export default TopBar;

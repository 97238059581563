import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || 'repeat(4, 1fr)'};
  grid-template-rows: ${({ rows }) => rows || 'auto'};
  grid-gap: ${({ gap }) => gap || '20px'};
  justify-content: ${({ justifyContent }) => justifyContent || 'start'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  justify-items: ${({ justifyItems }) => justifyItems || 'stretch'};
  align-content: ${({ alignContent }) => alignContent || 'start'};
  grid-auto-flow: ${({ autoFlow }) => autoFlow || 'row'};
  grid-auto-rows: ${({ autoRows }) => autoRows || 'auto'};
  grid-auto-columns: ${({ autoColumns }) => autoColumns || 'auto'};

  @media (max-width: 1200px) {
    grid-template-columns: ${({ tabletColumns }) =>
      tabletColumns || 'repeat(3, 1fr)'};
  }

  @media (max-width: 768px) {
    grid-template-columns: ${({ mobileColumns }) =>
      mobileColumns || 'repeat(2, 1fr)'};
    grid-gap: ${({ mobileGap }) => mobileGap || '15px'};
  }

  @media (max-width: 480px) {
    grid-template-columns: ${({ smallMobileColumns }) =>
      smallMobileColumns || '1fr'};
  }
`;

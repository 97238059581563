import styled from "styled-components";

export default styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(248, 248, 248, 0.85);
  box-shadow: 0 4px 15px rgb(0 0 0 / 7%), 0 1px 3px rgb(0 0 0 / 7%);
  margin-top: 77px;
  overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 10px;

  .modal-close {
    text-align: right;
    margin: -10px -10px -36px 0;

    svg {
      transition: all 0.3s;
      cursor: pointer;
      font-size: 3rem;
      padding: 0.1rem;

      &:hover {
        // transform: rotate(90deg);
        color: ${(props) => props.theme.red};
        border-color: ${(props) => props.theme.red};
        // background: #f3f3f3;
      }
    }
  }

  .modal-body {
    box-shadow: 0 4px 15px rgb(0 0 0 / 7%), 0 1px 3px rgb(0 0 0 / 7%);
    width: 480px;
    min-width: 300px;
    padding: 25px 15px;
    text-align: left;
    border-radius: 10px;
    background-color: #fff;
    border: ${(props) => props.theme.border};
    border-radius: ${(props) => props.theme.borderRadius};
    margin: 0 auto;
    position: relative;

    .modal-close-button {
      position: absolute;
      top: -15px;
      right: -15px;
      background: none;
      border: none;
      cursor: pointer;
    }

    .modal-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 1rem;
      text-align: center;
    }

    .modal-wrapper {
      button {
        outline: none;
        color: white;
        margin-left: 1rem;
        padding: 1rem 3rem;
        font-weight: bold;
        border-radius: 5px;
        font-size: 14px;
        border: none;
        text-transform: uppercase;
        cursor: pointer;
        line-height: 2rem;
        margin-top: 10px;
        width: initial;
      }

      button.btn-delete {
        background-color: ${(props) => props.theme.red};
      }

      button.btn-cancel {
        background-color: ${(props) => props.theme.blue};
      }
    }
  }
`;

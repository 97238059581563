import React from 'react';
import { Link } from 'react-router-dom';
import { Trash3, PencilSquare, Diagram2 } from 'react-bootstrap-icons';

import styles from './ClientCard.module.scss';

import formatDateWithPastWeek from '../../utils/formatDateWithPastWeek';
import placeholderSVG from '../../images/logo-placeholder.svg';

const ClientCard = ({ client, openDeleteModal, openEditModal }) => {
  return (
    <div className={styles.root}>
      <div className={styles.settings}>
        <button
          onClick={() => openDeleteModal(client)}
          className={styles.settingsButton}
        >
          <Trash3 />
        </button>
        <button
          onClick={() => openEditModal(client)}
          className={styles.settingsButton}
        >
          <PencilSquare />
        </button>
      </div>
      <Link className={styles.link} to={`/client/${client._id}`}>
        <div className={styles.logoBox}>
          <img
            className={styles.logo}
            src={client.logo || placeholderSVG}
            alt={`${client.name} Logo`}
          />
        </div>
        <div className={styles.description}>
          <div className={styles.descriptionTop}>
            <h3>{client.name}</h3>
            {client?.notes && <p className={styles.notes}>{client?.notes}</p>}
          </div>
          <div className={styles.descriptionBottom}>
            <span className={styles.created}>
              {formatDateWithPastWeek(client.created)}
            </span>
            <div className={styles.campaigns}>
              <Diagram2 width="16px" height="16px" />
              <p>{client.campaignCount} campaigns</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ClientCard;
